import React, { useContext, useEffect, useState, useRef } from "react";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import classes from "./ServiceInputs.module.css";
import FullDateDropdown from './FullDateDropdown';
import PrettyTextInput from "./PrettyTextInput";

const PartTimeServiceInput = (props) => {
  const scrollPositionsStart = useRef({})
  const scrollPositionsEnd = useRef({})
  const { inputsRef} = useContext(InputDataContext);
  const [partTimeService, setPartTimeService] = useState(
    inputsRef.current.partTimeService
  );
  const setInputs = props.setInputs
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [partTimeHours, setPartTimeHours] = useState("");  
  const [fullTimeHours, setFullTimeHours] = useState("");  
  const [error, setError] = useState(null);

  const initial_mount = useRef(true)

  const addService = () => {
    const newService = {
      From: startDate,
      To: endDate,
      partTimeHours: partTimeHours,  
      fullTimeHours: fullTimeHours, 
    };

    if (startDate >= endDate) {
      setError("Start date must be before end date.");
      return;
    }

    if (partTimeHours < 0 || partTimeHours > 60) {
      setError("Part-time hours must be between 0 and 60.");
      return;
    }

    if (fullTimeHours < 0 || fullTimeHours > 60) {
      setError("Full-time hours must be must be between 0 and 60.");
      return;
    }

    const isOverlap = (service1, service2) => {
      const start1 = new Date(service1.From);
      const end1 = new Date(service1.To);
      const start2 = new Date(service2.From);
      const end2 = new Date(service2.To);

      return start1 <= end2 && start2 <= end1;
    };

    for (let i = 0; i < partTimeService.length; i++) {
      if (isOverlap(partTimeService[i], newService)) {
        setError("This entry overlaps with an existing part-time service.");
        return;
      }
    }

    const isChronalogical = (service1, service2) => {
      const start1 = new Date(service1.From);
      const start2 = new Date(service2.From);

      return start1 >= start2;
    };

    for (let i = 0; i < partTimeService.length; i++) {
      if (isChronalogical(partTimeService[i], newService)) {
        setError("Please enter services in chronalogoical order.");
        return;
      }
    }

    if(fullTimeHours === '' || partTimeHours === '') {
      setError("Part- and Full-time hours must be specified.");
      return
    }

    if(Number(fullTimeHours) < Number(partTimeHours) ) {
      setError("Full-time hours cannot be less than part-time hours");
      return
    }

    console.log('Minimum joining date', props.min_date)
    console.log(parseDate(startDate))
    if(props.min_date && (props.min_date > parseDate(startDate))) {
      setError('Your start date cannot be before the date you joined the scheme')
      return
    }

    const new_service = [
      ...partTimeService,
      { From: startDate, To: endDate,  partTimeHours: partTimeHours,  
        fullTimeHours: fullTimeHours },
    ]
    setPartTimeService(new_service);

    if(setInputs) {
      setInputs((prev) => {return({...prev, partTimeService : new_service})});
    }
    
    inputsRef.current =  {...inputsRef.current, partTimeService : new_service}

    setStartDate("");
    setEndDate("");
    setPartTimeHours("");  
    setFullTimeHours("");   
    setError(null);
  };

  function parseDate(input) {
    const [year, month, day] = input.split('-');
    return new Date(year, month - 1, day); // JavaScript months are zero-indexed
}

//This function calculates how much service the member would have accrued over the remedy period, 
//given their part-time working pattern.
function calculateFTE(arr) {
  
    const startDate = parseDate(String(props.min_year) + '-04-01');
    const final_date = parseDate('2022-03-31');
    const totalPeriodDays = (final_date - startDate) / (1000 * 60 * 60 * 24) + 1;
    const totalPeriodYears = totalPeriodDays / 365.25

    let coveredDays = 0;
    let fteDays = 0;

    arr.forEach(period => {
      if(parseDate(period.From) < final_date) {
        const from = parseDate(period.From);
        var to = parseDate(period.To);
        if(to > final_date) {
          to = final_date
        }
        const daysInPeriod = (to - from) / (1000 * 60 * 60 * 24) + 1;
        coveredDays += daysInPeriod;

        // Corrected proportion calculation
        const proportion = period.partTimeHours / period.fullTimeHours;
        fteDays += daysInPeriod * proportion;
      }
        
    });

    // Calculate days not covered by the array
    const uncoveredDays = totalPeriodDays - coveredDays;
    // Assume 100% FTE for uncovered days
    fteDays += uncoveredDays;

    return (fteDays / totalPeriodDays) * totalPeriodYears;
}



  const removeService = (index) => {
    
    const new_service = partTimeService.filter((_, i) => i !== index)
    setPartTimeService(new_service);
    console.log(setInputs)
    if(setInputs) {
      setInputs((prev) => {return({...prev, partTimeService : new_service})});
    }
    inputsRef.current =  {...inputsRef.current, partTimeService : new_service}
    setStartDate("");
    setEndDate("");
    setPartTimeHours("");  
    setFullTimeHours("");  
    setError(null);
    
  };

  useEffect(() => {
    if(props.set_show_remedy_warning && inputsRef.current.BenefitStatement === 'Yes' && !initial_mount.current) {
      if(partTimeService.length > 0) {
        inputsRef.current.hasPT = 'Yes'
      }

      
      const remedy_service = calculateFTE(partTimeService)
      console.log('min serv implied by pt', remedy_service)
      
      inputsRef.current.min_service_implied_by_pt = remedy_service ;

      const s_years = inputsRef.current.serviceYears || 0
      var s_days = inputsRef.current.serviceDays || 0
      if(s_days === '' || s_days === 'xxx' || s_days === '0') {
        s_days = 0
      }
      
      console.log('service years and days:', (s_years + s_days/365), s_years, s_days)
      const remedy_warning_needed = remedy_service > (s_years + s_days/365)
      
      props.set_show_remedy_warning(remedy_warning_needed)
    }

    if(initial_mount.current) {
      initial_mount.current = false
    }
  }, [partTimeService]);

  return (
    <div className={classes.service_container + ' ' + (props.visible ? '' : classes.invisible)}>
      <h3>Tell us about your part-time working history</h3>
      
      
      <p>
        Please enter all periods of part-time working up to your chosen retirement date. 
        You don’t need to include any full-time periods of working in between separate 
        periods of part-time working. 
      </p>
      <p>
        If you want to use this illustrator to understand how retiring at different ages 
        could affect the value of your pension, it’s best to include an end date for 
        working part-time that is beyond the latest retirement date you want to explore.  
      </p>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className = {classes.line} style = {{'marginTop' : '5%'}}> </div>
      <div className={classes.subContainer}>
        <div className={classes.inputContainer}>
          <div className={classes.label_input}>
            <label>Start Date:</label>
            <FullDateDropdown
          existing_value = {startDate}
          onChange = {(e) => setStartDate(e.target.value)}
          scrollPositions = {scrollPositionsStart}
          min_year = {props.min_year}
          max_year = {props.max_year}
          min_month = {[2014, 2015].includes(props.min_year) ? 3 : 0}  //corresponding to April if benefit statement available
          />
          </div>
          <div className={classes.label_input}>
            <label>End Date:</label>
            <FullDateDropdown
          existing_value = {endDate}
          onChange = {(e) => setEndDate(e.target.value)}
          scrollPositions = {scrollPositionsEnd}
          min_year = {props.min_year}
          max_year = {props.max_year}
          min_month = {3 }// corresponding to April
          />

          </div>
          <div style = {{'--box-width' : '80%', 'display' : 'flex', '--centralise' : 'flex'}}> 
            <PrettyTextInput
            left_margin = {props.left_margin}
            label = {"Part-time hours"}
            value ={partTimeHours}
            onChange = {(e) => setPartTimeHours(e.target.value)} 
            showError = {partTimeHours > 60}
            error = {"part-time hours too high"}

            />
            <PrettyTextInput
            label = {"Full-time hours"}
            value ={fullTimeHours}
            onChange = {(e) => setFullTimeHours(e.target.value)} 
            showError = {fullTimeHours > 60}
            error = {"full-time hours too high"}
            left_margin = {props.left_margin}

            />
            <div className = {classes.button_container}> 
              <button onClick={addService}>Add Service</button>
            </div>
        </div>  
         
        </div>
        <div className = {classes.line} style = {{'marginBottom' : '3%'}}> </div>
        
      </div>
      <div className = {classes.table_container}>
        <p> Service that you add will appear in the table below.</p>
        <table>
          <thead>
            <tr>
              <th className={classes.leftColumn}></th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Part-Time Hours</th>
              <th>Full-Time Hours</th>
              <th className={classes.rightColumn}></th>
            </tr>
          </thead>
          <tbody>
            {partTimeService.map((service, i) => (
              <tr key={i}>
                <td className={classes.leftColumn}>{i + 1}.</td>
                <td>{new Date(service.From).toLocaleDateString("en-GB")}</td>
                <td>{new Date(service.To).toLocaleDateString("en-GB")}</td>
                <td>{service.partTimeHours}</td>  
                <td>{service.fullTimeHours}</td>  
                <td className={classes.rightColumn} style = {{'border' : 'none'}}>
                  <button onClick={() => removeService(i)}>X</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {partTimeService.length > 0 && <p> Click 'X' to remove a row of part-time service history from the table</p>}
      </div>
    </div>
  );
};

export default PartTimeServiceInput;

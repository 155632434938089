import React, { useState, useEffect, useRef } from 'react';
import classes from './MultipleChoice.module.css'

const MultipleChoice = (props) => {
    const options_mapper = {
        'Yes' : 'Yes, take me to the partial retirement calculator', 
        'No' : "No, continue with the normal calculator"
      }
    
      if(props.input === 'PAR') {
        
        var options = props.options.map(x => options_mapper[x])
        
      } else {
        var options = props.options
      }
    return(
        <div className= {classes.multiple_choice}> 
            {options.map(
                (option, index) => {return(
                <button
                key = {option}
                className = {(props.options[index] === props.chosen ? classes.pressed : classes.unpressed)}
                onClick = {() => props.changeHandler({'target' : {'value' : props.options[index]}})}
                > {option} </button>
                 )}
            )}

        </div>
    )
}

export default MultipleChoice
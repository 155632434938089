import React, { useState, useEffect, useRef } from 'react';
import classes from './FulDateDropdown.module.css'
import DateDropdown from './DateDropdown';

const FullDateDropdown = (props) => {
    
  const initial_mount = useRef(true)
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
  ];

  const min_year = props.min_year
  const min_month = props.min_month === undefined ? 0 : props.min_month
  const min_day = props.min_day === undefined? 0 :props.min_day

  const max_year = props.max_year
  const max_month = props.max_month === undefined ? 11 : props.max_month
  const max_day = props.max_day === undefined? 31 :props.max_day



  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }
  function get_max_possible_day(year, month) {
    if (month === "February") {
      return isLeapYear(year) ? 29 : 28;
    } else if (["April", "June", "September", "November"].includes(month)) {
      return 30;
    } else {
      return 31;
    }
  }

  const [year, set_year] = useState('Year')
  const [month, set_month] = useState('Month')
  const [day, set_day] = useState('Day')

  useEffect(
    () => {
      if(props.existing_value !== '') {
        const ev = props.existing_value
        var existing_year = Number(ev.slice(0,4))
        var existing_month = ev.slice(5,7)
        existing_month = months[Number(existing_month) - 1]
        var existing_day = ev.slice(8,10)
        existing_day = Number(existing_day)

        set_day(existing_day)
        set_month(existing_month)
        set_year(existing_year)
        
      } else {
        set_year('Year')
        set_month('Month')
        set_day('Day')

        
      }
      initial_mount.current = false
    },
    [props.existing_value]
  )

  const num_year = Number(year)
  const num_day = Number(day)

  const dayRef = useRef(day)
  const monthRef = useRef(month) 
  useEffect(
      () => {


        if(dayRef.current === day) {
          //max_possible_day means the latest day of the month. E.g. if the year is 2004 and 
          //the month is February, max_possible_day will be 29.
          //Conversely, `max_day` refers to the max_day prop that informs us 
          //of the day of the latest date that the user is allowed to enter.
          var max_possible_day = get_max_possible_day(year, month)
          if(num_day < min_day && num_year === min_year && months.indexOf(month) === min_month) {
            //in which case the year is the lowest possible,
            //the month is the lowest possible, so we do not allow the day to be 
            //earlier than `mid_day`. 
            //Note that the next if condition cannot be satisfied if this one has been satisfied.
            set_day(min_day)
            dayRef.current = day
            return
          }

          
          if(num_day > max_day && num_year === max_year && months.indexOf(month) === max_month) {
            //in which case the year is the lowest possible,
            //the month is the lowest possible, so we do not allow the day to be 
            //earlier than `mid_day`. 
            
            set_day(max_day)
            dayRef.current = day
            return
          } else if(max_possible_day < num_day) {
            set_day(max_possible_day)
            dayRef.current = day
            return
          }
        }

        if(monthRef.current === month && (month !== 'Month')) {
          
          if((min_month > months.indexOf(month)) && (num_year === min_year)) {
            set_month(months[min_month])
            set_day(Math.max(day, min_day))
            dayRef.current = day
            monthRef.current = month
            return
          }

          if((max_month < months.indexOf(month)) && num_year === max_year) {
            set_month(months[max_month])
            set_day(Math.min(day, max_day))
            dayRef.current = day
            monthRef.current = month
            return
          }
        }

        if(
            (year !== 'Year') && (month !== 'Month') && (day !== 'Day') &&
            ((String(year) + '-' + String(month) + '-' + String(day)) !== props.existing_value)
          ) {
              
          if(String(day).length === 1) {
            
            var formatted_day = '0' + day
          } else {
            var formatted_day = day
          }
          var formatted_month = months.indexOf(month) + 1

          if(String(formatted_month).length === 1) {
            formatted_month = '0' + formatted_month
          }
          
          props.onChange(
              {'target' : {'value' : String(year) + '-' + String(formatted_month) + '-' + String(formatted_day)}}
          )
        }
          

          dayRef.current = day
          monthRef.current = month
          
      }, 
      [day, month, year]
  )

  let years = [];
  for (let y = min_year; y <= props.max_year; y++) {
  years.push(y);
  }




  // Function to set the correct number of days based on month and year
  function setDays(m, y) {
    let days = [];
    var daysInMonth = get_max_possible_day(y, m)

    var start_day = 1

    if(num_year === min_year && months.indexOf(month) === min_month){
      start_day = min_day
    }

    if(num_year === max_year && months.indexOf(month) === max_month) {

      daysInMonth = max_day
    }

    for (let d = start_day; d <= daysInMonth; d++) {
      days.push(d);
    }

    return days;
  }

  let days = setDays(month, year);

  const month_options = months.slice(
    min_year === year ? min_month : 0,
    max_year === year ? (max_month+1) : 12
  )


return (
    <div className = {classes.dropdowns_container}> 
<DateDropdown options = {days} value = {day} set_value = {set_day} name = {'day'} scrollPositions = {props.scrollPositions} go_red = {props.go_red}/>
<DateDropdown options = {
      month_options
    } 
    value = {month} 
    set_value = {
    (x) => {
      var month_index = months.indexOf(x)
      if(year === min_year) {
        month_index = Math.max(min_month, month_index)
      }
      if(year === max_year) {
        month_index = Math.min(max_month, month_index)
      }
      set_month(months[month_index])
  }
  } name = {'month'} scrollPositions = {props.scrollPositions} go_red = {props.go_red}/>
<DateDropdown options = {years} value = {year} set_value = {set_year} name = {'year'} scrollPositions = {props.scrollPositions} go_red = {props.go_red}/>
</div>

)
}

export default FullDateDropdown
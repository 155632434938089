import React from "react";
import { currencyFormatter } from "../../../shared/utilities/Formatters";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle
);

function BarChart(props) {
  const remedyBarColor = "rgba(0, 169,206)";
  const post2022BarColor = "rgba(118,134,146, 60%)";
  const titleFontSize = 18;
  const normalFontSize = 14;
  const fontColor = "rgb(0,0,0)";


const chartData = {
            labels: [
              ["Option A -", "Standard Benefits"],
              ["Option B -", "Alternative Benefits"],
            ],
            datasets: [
              {
                label: props.legacySchemeName + ' pension',
                data: [props.dummy_data['choose_legacy']['old'], props.dummy_data['choose_reformed']['old']],
                borderColor: remedyBarColor,
                backgroundColor: remedyBarColor,
              },
              {
                label: props.remedySchemeName + ' pension',
                data: [props.dummy_data['choose_legacy']['new'], props.dummy_data['choose_reformed']['new']],
                borderColor: post2022BarColor,
                backgroundColor: post2022BarColor,
              },
            ],
          }
const chartOptions = {
      Chart: { defaults: { color: [fontColor] } },
      indexAxis: "x",
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      // hover: null,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
          onClick: false,
        },
        title: {
          display: true,
          text: "Estimated Annual Pension before tax at age " + props.retirementAge,
          font: {
            weight: "bold",
            size: titleFontSize,
          },
          padding: {
            bottom: 1,
          },
          color: [fontColor],
        },
        subtitle: {
          display: true,
          text: "Custom Chart Subtitle",
          text: "(Values in " + props.adjustInflationState + " terms)",
          font: {
            // weight: "bold",
            size: normalFontSize,
          },
          padding: {
            bottom: 20,
          },
          color: [fontColor],
        },
        interation: {
          mode: "y",
        },
        tooltip: {
          backgroundColor: "white",
          borderColor: "black",
          borderWidth: 1,
          titleColor: fontColor,
          titleFont: {
            size: normalFontSize,
          },
          bodyColor: fontColor,
          bodyFont: {
            size: normalFontSize,
          },
          yAlign: "center",
          callbacks: {
            title: function (context) {
              return context[0].label.replaceAll(",", " ");
            },
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.x !== null) {
                label += currencyFormatter(context.parsed.y);
              }
              return label;
            },
          },
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value, context) => {
            const datasetArray = [];
            context.chart.data.datasets.forEach((dataset) => {
              if (dataset.data[context.dataIndex] !== undefined) {
                datasetArray.push(dataset.data[context.dataIndex]);
              }
            });
            function totalSum(total, datapoint) {
              return total + datapoint;
            }
            let sum = datasetArray.reduce(totalSum, 0);
            if (context.datasetIndex === datasetArray.length - 1) {
              return "Total Pension: " + currencyFormatter(sum);
            } else {
              return "";
            }
          },
          font: {
            size: normalFontSize,
          },
          color: [fontColor],
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "Value in " + props.adjustInflationState + " terms",
            font: {
              size: normalFontSize * 1.2,
            },
            color: fontColor,
          },
          stacked: true,
          grid: { display: false },
          ticks: {
            callback: function (value) {
              return currencyFormatter(value);
            },
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
          // grace: 1000,
        },
        x: {
          stacked: true,
          grid: { display: false },
          ticks: {
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
        },
      },
    }

  return (
    <Bar options={chartOptions} data={chartData} plugins={[ChartDataLabels]} />
  );
}

export default BarChart;

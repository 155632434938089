export const currencyFormatter = (value) => {
  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
    roundingMode: "halfExpand",
  }).format(value);
};

export const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b);

export function formatDate(inputDate, version = 'long') {
  // Split the date string
  const parts = inputDate.split('-');
  const year = parts[0];
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // Determine the ordinal suffix
  let ordinal = 'th';
  if (day === 1 || (day > 20 && day % 10 === 1)) {
      ordinal = 'st';
  } else if (day === 2 || (day > 20 && day % 10 === 2)) {
      ordinal = 'nd';
  } else if (day === 3 || (day > 20 && day % 10 === 3)) {
      ordinal = 'rd';
  }

  // Map month number to month name

  const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const short_months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  
  // Ensure the date is valid (this covers the leap years too)
  if (new Date(inputDate).toISOString().slice(0,10) !== inputDate) {
      throw new Error('Invalid date');
  }

  return `${day}${ordinal} ${version === 'long' ? months[month - 1] : short_months[month - 1]} ${year}`;
}

export function poundToNumber(formattedString) {

  const formattedStringType = String(formattedString)
  if(formattedStringType === '' || formattedStringType === '£' || formattedStringType === '£0' || formattedStringType === '0') {

    return '0'
  }

  const cleanedString = formattedStringType.replace(/[^0-9.-]+/g, "");
  
  if (cleanedString.endsWith('.') || cleanedString.endsWith('.0')) {

      return cleanedString;  // Return the string as-is if it ends with a dot
  }

  return parseFloat(cleanedString);
}

export function formatDateObject(date) {
  let day = date.getDate(); // get the day of the month
  let month = date.getMonth() + 1; // getMonth returns 0-11
  let year = date.getFullYear();

  // Pad the day and month with leading zeros if necessary
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;

  return `${day}/${month}/${year}`;
}

export function moveDateByYears(currentDate, years) {
  // Create a new Date object from the input date to avoid modifying the original date
  let newDate = new Date(currentDate);

  // Get the full year from the current date and add the number of years to it
  newDate.setFullYear(newDate.getFullYear() + years);

  // Return the new date with the adjusted year
  return newDate;
}

export function getLatestDate(date1, date2) {
  // Compare the numeric values of the dates
  if (date1.getTime() > date2.getTime()) {
      return date1;
  } else {
      return date2;
  }
}

export function setToStartOfDay(date) {
  if (date instanceof Date) {
    let ret = date
      ret.setHours(0, 0, 0, 0);
      return ret
  } else {
      throw new TypeError('The provided argument is not a Date object.');
  }
}
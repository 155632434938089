import React, { Fragment, useState, useEffect, useRef } from "react";
import InfoModal from "../../general/InfoModal";
import { submitInputData } from "../../../shared/apis/mainAPI";
import {textToPercent, processPercentDelete, sanitisePercentString} from '../../../utilities/PercentageHandlingFunctions'
import MiniSlider from "./MiniSlider";

import classes from "./ControlSwitches.module.css";

const LumpSumSwitch = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [commutation, setCommutation] = useState(props.inputsRef.current.commutation)
  const [equaliseLumpSum, setEqualiseLumpSum] = useState(props.inputsRef.current.equalise)
  const [text_focused, set_text_focused] = useState(false)
  const min_commutation = 0
  const max_commutation = 35.7
  const equalised_commutation_rate = props.equalised_commutation_rate
  const outputData = props.outputData

  function changeHandler(event) {
    
    const old_val = textToPercent(commutation);
    
    var new_val = processPercentDelete(event.target.value, old_val).replace(
      "%",
      ""
    );
  
    new_val = sanitisePercentString(new_val);
   

    const ends_with_decimal = new_val.charAt(new_val.length - 1) === '.'
    new_val = Math.min(Number(new_val/100), max_commutation/100)
    new_val = Math.max(new_val, min_commutation/100)
   
    setCommutation(String(Math.round(new_val*1000)/10) + (ends_with_decimal ? '.' : ''))
  }

  const [mouseDown, setMouseDown] = useState(false)
  const initial_mount = useRef(true)

  useEffect(() => {
    if ((!initial_mount.current) && !mouseDown &&!text_focused) {
      
  
      // Define an async function inside useEffect
      const fetchData = async () => {
        props.inputsRef.current['commutation'] = commutation;
        props.inputsRef.current['equalise'] = equaliseLumpSum;
        try {
          const data = await submitInputData(
            {...props.inputsRef.current, 'commutation': commutation, 'equalise': equaliseLumpSum}
          );
          props.setOutputData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle any errors here
        }
      };
  
      // Call the async function
      fetchData();
    } else {
      initial_mount.current = false;
    }
  }, [commutation, equaliseLumpSum, mouseDown, text_focused]);
  
  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const sliderHandler = (event) => {
    setMouseDown(true);
    setCommutation(event.target.value);
  };

  const infoMessage = (
    <Fragment>
      <h2>Showing equal lump sums</h2>
      <p>
        Each part of the Scheme has different lump sum rules. For example, you’ll get an automatic lump sum from any 
        service you have in the 1995 Section, which is usually up to three times your pension. The 2015 Scheme has no 
        automatic lump sum, but you can choose to permanently reduce your pension in exchange for a one-off lump sum. 
      </p>
      <p>
        The starting point for your illustration applies the relevant lump sum rules for each part of the Scheme, 
        which means we’ve shown your automatic lump sum for any 1995 Section service you have, but no lump sum for any 
        2008 Section or 2015 Scheme Service you have.
      </p>
      <p>
        When the switch is set to ‘unequal’, your illustration assumes you won’t permanently reduce any of your annual 
        pension in exchange for a one-off lump sum  unless you manually adjust this using the sliders described below.
         This means you’ll get an automatic lump sum for any membership you 
        have in the 1995 Section service, but nothing over the 1995 Section automatic amount, and no lump sum from 
        the 2008 Section or 2015 Scheme. 
      </p>
      <p>
        When the switch is set to ‘equal’, you can see how much annual pension you’d need to permanently reduce if you 
        choose Option B to get the same lump sum as in Option A, including any automatic lump sum from the 1995 Section. 
      </p>
      <p>
        The four sliders allow you to explore how taking different amounts of lump sum from each part of the Scheme 
        affects your benefits overall. Each slider is tailored to show you up to the maximum possible lump sum you can take, 
        and the graphs above show how your pension changes depending on the amount of lump sum you take. 
      </p>
      <p>
        For example, if you choose to take the maximum lump sum from both parts of the Scheme in Option A, and then press 
        ‘equal’, you can see how much pension you would have to permanently exchange to pay for the same amount of lump sum 
        in Option B. 
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container + ' '+ classes.els + ' ' + classes.lump_sum} 
    
    >
      <div className = {classes.els_inner_container}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} width = {'60rem'}/>
      )}
      <header className={classes.header}>
        <h3>Explore your lump sum options</h3>
        <button
          id="LumpSumSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>
      <p> Different parts of the Scheme have different lump sum rules.  When the switch is set to ‘equal’, you can see how much annual pension you’d need to permanently reduce if you choose Option B to get the same lump sum as in Option A, including any automatic lump sum from the 1995 Section.
      </p>
      <div className={classes.radio_container}>
        <input
          type="radio"
          id="radioUnequal"
          name="radioEqualiseLumpSum"
          value="unequal"
          onChange={
            () => {
              setEqualiseLumpSum("unequal")
              props.inputsRef.current.equalise = 'unequal'
            }
            }
            checked={equaliseLumpSum === "unequal"}
        />
        <label htmlFor="radioUnequal">Unequal</label>
        <input
          type="radio"
          id="radioEqual"
          name="radioEqualiseLumpSum"
          value="equal"
          onChange={
            () => {
              setEqualiseLumpSum("equal")
              props.inputsRef.current.equalise = 'equal'
            }
            }
            checked={equaliseLumpSum === "equal"}
        />
        <label htmlFor="radioEqual">Equal </label>
      </div>
      
      </div>
      <br></br>
      <p>
        Or use the sliders below to explore how much lump sum you could take from each part of the Scheme, 
        whether you take Option A or Option B. You can see in the graph above how this affects your annual 
        pension. Each slider is tailored to show you the maximum possible lump sum you can take from each 
        part of the Scheme.
      </p>
      <div className = {classes.mini_slider_container}> 
      {/*
            ##the values in the 'pretend' keys below are used to tell React where to position the slider
            ##when the max or min value is selected.
            ##The slider will only go in multiples of 50, so we cannot use the exact value to determine
            ##the position of the slider.
            ##So we create 'pretend' values that are the real values rounded to the nearest 50.
            ##React will base the slider position on the pretend values if the user selects the min or max,
            ##though the amount shown in the corresponding textbox will be the associated real value.
            ##Likewise, the backend will use the real value.
            */
          }

      <MiniSlider
      title = {'Option A - ' + props.schemeName}
      max = {outputData.max_legacy_lump_sum_choose_legacy}
      min = {outputData.min_legacy_lump_sum_choose_legacy}
      setOutputData = {props.setOutputData}
      pretend_max = {outputData.max_legacy_lump_sum_choose_legacy_pretend}
      pretend_min = {outputData.min_legacy_lump_sum_choose_legacy_pretend}
      selected = {outputData.selected_LP_lump_sum_choose_legacy}
      name_for_backend = {'selected_LP_lump_sum_choose_legacy'}
      />

      <MiniSlider
      title = {'Option B - ' + props.schemeName}
      max = {outputData.max_legacy_lump_sum_choose_care}
      min = {outputData.min_legacy_lump_sum_choose_care}
      setOutputData = {props.setOutputData}
      pretend_max = {outputData.max_legacy_lump_sum_choose_care_pretend}
      pretend_min = {outputData.min_legacy_lump_sum_choose_care_pretend}
      selected = {outputData.selected_LP_lump_sum_choose_care}
      name_for_backend = {'selected_LP_lump_sum_choose_care'}
      />
      
      <MiniSlider
      title = {'Option A - 2015 Scheme'}
      max = {outputData.max_care_lump_sum_choose_legacy}
      min = {outputData.min_care_lump_sum_choose_legacy}
      setOutputData = {props.setOutputData}
      pretend_max = {outputData.max_care_lump_sum_choose_legacy_pretend}
      pretend_min = {outputData.min_care_lump_sum_choose_legacy_pretend}
      selected = {outputData.selected_CP_lump_sum_choose_legacy}
      name_for_backend = {'selected_CP_lump_sum_choose_legacy'}
      />

   

    <MiniSlider
      title = {'Option B - 2015 Scheme'}
      max = {outputData.max_care_lump_sum_choose_care}
      min = {outputData.min_care_lump_sum_choose_care}
      setOutputData = {props.setOutputData}
      pretend_max = {outputData.max_care_lump_sum_choose_care_pretend}
      pretend_min = {outputData.min_care_lump_sum_choose_care_pretend}
      selected = {outputData.selected_CP_lump_sum_choose_care}
      name_for_backend = {'selected_CP_lump_sum_choose_care'}
      />

     
      
      </div>
      
    </div>
  );
};

export default LumpSumSwitch;

import classes from './ResultsTable.module.css'
import React, { Fragment, useContext, useEffect, useState } from "react";
import { InputDataContext } from '../../shared/contexts/InputDataContext';
import { OutputDataContext } from '../../App';
import { formatDate, currencyFormatter } from '../../utilities/Formatters';

const ResultsTable = (props) => {
    const { inputsRef} = useContext(InputDataContext);
    const { outputDataRef } = useContext(OutputDataContext);
    console.log('outputs', outputDataRef.current)


    const status = props.status
    const spa_years = inputsRef.current.spa_age['years']
    const spa_months = inputsRef.current.spa_age['months']
    const spa_age = spa_years + spa_months / 12
    const npa_mapper = {
        '1995 Section' : status === 'Neither of these' ? 60 : 55,
        '2008 Section' : 65, 
        '2015' : Math.max(spa_age, 65)
    }
    const legacy_scheme = inputsRef.current.legacyScheme
    const legacy_npa = npa_mapper[legacy_scheme]
    const reformed_npa = npa_mapper['2015']
    const ret_age = inputsRef.current.retirementDate


    
    
    const dummy_number = 100
  

    return(
<div className = {classes.results_table}> 

 <h1 style = {{textAlign: 'center'}}> Simple view</h1>
 <p style = {{textAlign: 'center', fontSize: '1.6em'}}> Your choice of pension benefits at a glance </p>
 <p style = {{fontWeight: 'normal', fontSize: '1.2em'}}>
    This table illustrates the total value of your NHS Pension, and how this changes based on the benefits you 
    choose for the remedy period. The remedy period covers any pension benefits you built up between 1 April 2015 and 31 March 2022. 
 </p>

 <div className = {classes.boxes_container}> 
        <div className= {classes.box}> 
        <h2> Based on </h2>
        <div className = {classes.based_on}>
        <div><p> Retirement Date: </p> <p> {formatDate(inputsRef.current.retirementDate)}</p> </div>
        
        <div><p> Salary increase: </p> <p> {outputDataRef.current.salaryIncrease}%</p> </div>
        </div>
        </div>
    <div className = {classes.box + ' ' + classes.choose_option}> 
        <p>
            <span style = {{ fontSize: '1.2em'}}>Option A - Standard Benefits</span> 
            <br></br> 
            Choose {legacy_scheme} benefits for the remedy period 
        </p>
    </div>
    <div></div>
    <div className = {classes.box + ' ' + classes.choose_option}> 
        <p>
            <span style = {{ fontSize: '1.2em'}}>Option B – Alternative Benefits</span> 
            <br></br> 
            Choose 2015 Scheme benefits for the remedy period
        </p>
    </div>
    <div className = {classes.box}> 
        <h2> you will receive a pension of: </h2>
        <h1>  {currencyFormatter(
            outputDataRef.current.pension.choose_legacy.new + outputDataRef.current.pension.choose_legacy.old
        )}</h1>
    </div>
    <div></div>
    <div className = {classes.box}> 
        <h2> you will receive a pension of: </h2>
        <h1>  {currencyFormatter(
            outputDataRef.current.pension.choose_reformed.new + outputDataRef.current.pension.choose_reformed.old
        )}</h1>
    </div>
    <div className = {classes.box}> 
        <h2> and a lump sum of: </h2>
        <h1>  {currencyFormatter(outputDataRef.current['lump sum'].choose_legacy)}</h1>
    </div>
    <div></div>
    <div className = {classes.box}> 
        <h2> and a lump sum of: </h2>
        <h1>  {currencyFormatter(outputDataRef.current['lump sum'].choose_reformed)}</h1>
    </div>
    

 </div>
 <br></br>
 <p style = {{fontWeight: 'normal', fontSize: '1.2em'}}>
    Any pension benefits you built up before or after the remedy period aren’t affected by your choice, but they are included in the totals shown above. 
 </p>
</div>
    )
}

export default ResultsTable


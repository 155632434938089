import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import Input from "../components/yourdetails/inputs/Input";
import { spaAPI } from "../shared/apis/spaAPI";
import { InputDataContext } from "../shared/contexts/InputDataContext";
import PartTimeServiceInput2 from "../components/yourdetails/inputs/PartTimeServiceInput2";
import CalculateButton from "../components/yourdetails/CalculateButton";
import params from "../shared/utilities/global_parameters";
import InputRetAgeWarning from "../components/yourdetails/inputs/InputRetAgeWarning";
import { formatDateObject, moveDateByYears, setToStartOfDay } from "../utilities/Formatters";

import classes from "./2-YourDetails.module.css";

function YourDetailsPage() {

  const { inputsRef, validRef} = useContext(InputDataContext);
  console.log('inputsRef on your details', inputsRef.current)
  const [inputs, setInputs] = useState(inputsRef.current)
  const [valid, setValid] = useState(validRef.current)
  const [show_remedy_warning, set_show_remedy_warning] = useState(false)
  const today = new Date();
  //set date of birth to a dummy date if it has not been provided yet
  const birthday = (inputs.dateOfBirth ?  new Date(inputs.dateOfBirth) : today );
  
  
  // Adjust the birthday year to 55 years later
let retDateLower = new Date(birthday.getTime());  // Clone the original birthday
retDateLower.setFullYear(birthday.getFullYear() + 55);
const max_ret_date = moveDateByYears(birthday, 75)

// Check if the original date was Feb 29 and the target year is not a leap year
if (birthday.getMonth() === 1 && birthday.getDate() === 29 && !isLeapYear(retDateLower.getFullYear())) {
    // Set the date to February 28 or March 1 of the non-leap year
    retDateLower.setDate(28);  // Adjusting to February 28 for consistency
}

// Make sure the date is not before today
retDateLower = new Date(Math.max(retDateLower, today));
console.log('retdatelower', retDateLower)

//make spa_date a dummy value if inputs.spa does not exist yet
const spa_date = inputs.spa ? new Date(inputs.spa) : today

// Helper function to check if a year is a leap year
function isLeapYear(year) {
    return ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);
}


  let earliestDoB = today.setDate(today.getDate() - 1);
  earliestDoB = new Date(earliestDoB).setFullYear(
    new Date(earliestDoB).getFullYear() - 66
  );
  
  const initial_mount= useRef(true)
  
  let doj_date = new Date(inputs.dateOfJoining)
  doj_date =setToStartOfDay(doj_date)
  let apr_2008 = new Date('2008-04-01')
  apr_2008 = setToStartOfDay(apr_2008)
  let special_status_date = new Date('1995-03-06')
  special_status_date = setToStartOfDay(special_status_date)

  var min_pt_date
  var min_pt_year
  if(inputs.BenefitStatement === 'Yes' && inputs.status === 'Mental Health Officer') {
    min_pt_year = 2014
  } else if(inputs.BenefitStatement === 'Yes') {
      min_pt_year = 2015
    } else {
      min_pt_year = doj_date.getFullYear()
      min_pt_date = doj_date
    }
  



  const smoothScroll = (distance, duration) => {
    const startPosition = window.scrollY;
    const targetPosition = startPosition + distance; // This should be the final vertical position after scrolling

    let startTime = null;

    const ease = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const animation = currentTime => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, startPosition, targetPosition - startPosition, duration); // Using targetPosition - startPosition as delta
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
};

  const redirectToPAR = () => {  
    window.open("https://partialretirementcalculator.nhsbsa.nhs.uk/", "_blank");;  
  }; 

  useEffect(
    () => {if(inputs.PAR === 'Yes') {
    redirectToPAR()
    }}, 
    [inputs.PAR]
  )



  useEffect(
    () => {
      
      if(!initial_mount.current) {
      if (inputs.hasPT === 'Yes') {
        smoothScroll(500, 1200)
      }
      } else {
        initial_mount.current = false
      }
    }, 
    [inputs.hasPT]
  )

  let latestDoB = "1999-12-31";
  useEffect(() => {
    if (inputs.dateOfJoining) {
      latestDoB = new Date(inputs.dateOfJoining).setFullYear(
        new Date(inputs.dateOfJoining).getFullYear() - 16
      );
    } else {
      latestDoB = "1999-12-31";
    }
    inputsRef.current.legacyScheme = (new Date(inputs.dateOfJoining) < new Date("2008-04-01") ? '1995 Section' : '2008 Section')
    
  }, [inputs.dateOfJoining]);

  useEffect(() => {
    if(inputs.dateOfBirth) {
    spaAPI(new Date(inputs.dateOfBirth), inputs, setInputs);
    }
  }, [inputs.dateOfBirth]);




  useEffect(
    () => setValid((prev) => ({...prev, PAR: inputs.PAR === 'No'})), 
    [inputs.PAR]
  )
  useEffect(
    () => setValid((prev) => ({...prev, inReceipt: inputs.inReceipt === 'No'})), 
    [inputs.inReceipt]
  )
  useEffect(
    () => setValid((prev) => ({...prev, Practitioner: inputs.Practitioner === 'No'})), 
    [inputs.Practitioner]
  )
  useEffect(
    () => setValid((prev) => ({...prev, MaleNurse: inputs.MaleNurse === 'No'})), 
    [inputs.MaleNurse]
  )

  useEffect(
    () => {
      
      if(inputs.BenefitStatement === 'No' || inputs.BenefitStatement === undefined) {
        set_show_remedy_warning(false)
      } else {
        var s_days = inputs.serviceDays || 0
        //`s_days` could be a string, which means it is 0.
        s_days = isNaN(Number(s_days)) ? 0 : s_days
        const s_years = inputs.serviceYears || 0
        
        var min_service = inputsRef.current.min_service_implied_by_pt
        min_service = (min_service === undefined || inputsRef.current.hasPT === 'No' || isNaN(min_service)) ?
                      (2022 - min_pt_year) :
                      min_service
        

        if((s_days / 365 + s_years) < min_service) {
          set_show_remedy_warning(true)
        } else {
          set_show_remedy_warning(false)
        }
      }
    }, 
    [inputs.BenefitStatement, inputs.serviceDays, inputs.serviceYears, inputs.hasPT, inputs.status]
  )

  function Autofill() {
    setInputs(
      {
        PAR: 'No',
        inReceipt : 'No', 
        Practitioner : 'No',
        hasLeft : 'No', 
        dateOfBirth : '1980-01-01',
        dateOfJoining : '2000-01-01', 
        status: "Neither of these",
        MaleNurse: "No",
        BenefitStatement: "No",
        Pension2022: 50000, 
        Service2022: 30,
        salaryCurrent : 50000, 
        salary2015: 0, 
        retirementDate : '2040-01-01', 
        hasPT : 'No', 
        partTimeService: [],
        salaryIncrease: 2,
        commutation : 0, 
        equalise : 'unequal', 
        inflation : 'nominal',
        SchemeOptant : 'No'
      }
    )

    setValid({
      ...valid,
      PAR: true,
      inReceipt : true,
      Practitioner: true,
      dateOfBirth: true,
      dateOfJoining: true,
      MHO: true,
      MaleNurse: true,
      BenefitStatement: true,
      Pension2022: true, 
      Service2022: true,
      salaryCurrent: true,
      salary2015: true,
      retirementDate: true,
      hasPT: true,
      hasLeft : true, 
      status: true, 
      pension2015 : true,
      SchemeOptant : true
    })

  }


  
  useEffect(
    () => {
      inputsRef.current = {...inputs,
                           ...{'legacyScheme' : inputsRef.current.legacyScheme, 
                                'min_service_implied_by_pt' : inputsRef.current.min_service_implied_by_pt,
                                "selected_CP_lump_sum_choose_care": inputsRef.current['selected_CP_lump_sum_choose_care'],
                                "selected_CP_lump_sum_choose_legacy": inputsRef.current['selected_CP_lump_sum_choose_legacy'],
                                "selected_LP_lump_sum_choose_care": inputsRef.current['selected_LP_lump_sum_choose_care'],
                                "selected_LP_lump_sum_choose_legacy": inputsRef.current['selected_LP_lump_sum_choose_legacy']
                          }
                          }
    },
       [inputs]
  )

  useEffect(
    () => {validRef.current = valid}, [valid]
  )

  var spa_years
  var spa_months
  var legacy_npa
  var legacy_scheme
  var legacy_npa_date
  var reformed_npa_date
  if(inputs.spa_age) {
    var status = inputs.status
    spa_years = inputs.spa_age['years']
    spa_months = inputs.spa_age['months']
    var spa_age = spa_years + spa_months / 12
    var npa_mapper = {
        '1995 Section' : status === 'Neither of these' ? 60 : 55,
        '2008 Section' : 65, 
        '2015' : Math.max(spa_age, 65)
    }
    legacy_scheme = inputsRef.current.legacyScheme
    legacy_npa = npa_mapper[legacy_scheme]
    legacy_npa_date = moveDateByYears(birthday, legacy_npa)
    reformed_npa_date = new Date(inputs.reformed_npa_date)
  }
  

  const ret_help_message = <Fragment>
       <p> 
        <b>Each part of the Scheme has a different NPA:</b>
      </p>
      <p>
        For the 1995 Section, it’s age 60. For members with Special Class or Mental Health Officer status, it’s age 55. 
      </p>
      <p>
        For the 2008 Section, it’s age 65.
      </p>
      <p>
        For the 2015 Scheme, it’s the same as your State Pension Age, or age 65 if that is later.
      </p>
      <p>
        You can use the tools in the detailed results page of this illustrator to show you how claiming benefits at different times could affect the value of your pension.  
      </p>
  </Fragment>

  //make a shorthand for all the conditions that must be satisfied to reach 
  //the benefit statement

  

  const not_male_nurse_pre_1990 = (inputs.status !== "Special Class Officer" ||
  inputs.MaleNurse=== 'No' ||
  (doj_date >= new Date("1990-05-17"))
  ) 
  const scheme_optant_ok = ((doj_date >= apr_2008) || inputs.SchemeOptant === 'No')
  
  const status_ok = (doj_date >= special_status_date) || valid.status

  const move_on_from_dob = valid.PAR && 
  valid.inReceipt &&
  valid.Practitioner &&
  valid.hasLeft &&
  valid.dateOfBirth

  const show_autofill_development = true
  const show_autofill = show_autofill_development && (window.HOST_URL.includes("gad-hosting") || window.HOST_URL.includes("localhost"))

  return (
    <div id="Your_Details_Page" className = {classes.yourDetails} style = {{
      '--mh' : (inputs.hasPT === "Yes" ? '300vh' : (valid.salary2015 ? '270vh' :  (valid.inReceipt ? '147vh' : '97vh')) )
    }}>

      <h2>Tell us about your career</h2>
      {show_autofill && <button onClick = {Autofill}> 

        Autofill
      </button>}
      <p>
        Please answer the following questions to allow us to provide you with an
        estimate of your pension benefits.
      </p>


      <Input
        input={"inReceipt"}
        inputType={"select"}
        options={["Yes", "No"]}
        text={`Are you currently receiving all or part of your NHS pension?`}
        help={null}
        inputs = {inputs}
        setInputs = {setInputs}
        valid = {valid}
        setValid = {setValid}
        invisible = {false}
      />
      {inputs.inReceipt === "Yes" && (
        <div className={classes.input_container}>
          <p>
          As you have already retired or taken partial retirement, NHS Pensions will 
          automatically write to you with your remedy choice if you are affected. 
          </p>
        </div>
      )}


      <Input
          input={"hasLeft"}
          inputType={"select"}
          options={["Yes", "No"]}
          text={`Have you already left the NHS Pension Scheme?`}
          help={null}
          inputs = {inputs}
          setInputs = {setInputs}
          valid = {valid}
          setValid = {setValid}
          invisible= {inputs.inReceipt !== 'No'}
        />

        {inputs.hasLeft === "Yes" && (
        <div className={classes.input_container}>
          <p>
            You cannot use this illustrator if you have already left the scheme
          </p>
        </div>
      )}
      <Input  
      input={"PAR"}  
      inputType={"select"}  
      options={["Yes", "No"]}  
      text={`Are you planning on partially retiring?`}  
      help={null}  
      inputs={inputs}  
      setInputs={setInputs}   
      valid={valid}  
      setValid={setValid} 
      invisible = {
        !(inputs.hasLeft === 'No' && valid.inReceipt)
      } 
      />  
     
      
      
      <Input
        input={"Practitioner"}
        inputType={"select"}
        options={["Yes", "No"]}
        text={`Are you a practitioner?`}
        help={
          <Fragment> 
            <h1> A practitioner is defined as either: </h1>
            <div style={{fontSize: '16px', lineHeight: '1.5'}}>  
                <p>(a) a fully registered person (within the meaning of section 55 of the Medical Act 1983) who is not a GP registrar and is: </p>  
                <div style={{marginLeft: '20px'}}>  
                  <p>(i) a medical practitioner; or</p>  
                  <p>(ii) an ophthalmic practitioner; or</p>  
                  <p>(iii) a locum practitioner; or</p>  
                </div>  
                <p> </p>
                <p>(b) a dental practitioner.</p>  
              </div>  
          </Fragment>
        }
        inputs = {inputs}
        setInputs = {setInputs}
        valid = {valid}
        setValid = {setValid}
        invisible = {
          !(valid.PAR &&
            valid.inReceipt &&
            valid.hasLeft 
            )
        }
      />
      {inputs.Practitioner === "Yes" && (
        <div className={classes.input_container}>
          <p>
          We are sorry, practitioner members can’t use this illustrator. You will be sent a letter explaining your options, 
          including personalised figures, when you apply to retire.  
          </p>
        </div>
      )}

        <Input
          input={"dateOfBirth"}
          inputType={"date"}
          text={"What is your date of birth?"}
          inputs = {inputs}
          setInputs = {setInputs}
          valid = {valid}
          setValid = {setValid}
          min_year = {1953}
          min_month = {11}
          min_day = {6}
          max_year = {1996}
          help={
            <Fragment>
              <h2>Date of Birth</h2>
              <p>
                This calculator can only be used by those who have not yet
                reached their State Pension Age; entering a date of birth
                corresponding to a State Pension date that has been reached in
                the past is not possible.
              </p>
            </Fragment>
          }
          /*minValue={new Date(earliestDoB)}*/
          maxValue={new Date(latestDoB)}
          /*minValErrorMsg={
            "As you are over your State Pension Age, this illustrator is not suitable for you."
          }*/
          maxValErrorMsg={"The DoB date you've entered is too late."}
          invisible = {
            !(valid.hasLeft &&
              valid.PAR &&
              valid.inReceipt &&
              valid.Practitioner)
          }
        />


        <Input
          input={"dateOfJoining"}
          inputType={"date"}
          text={`What date did you join the NHS Pension Scheme?`}
          inputs = {inputs}
          setInputs = {setInputs}
          valid = {valid}
          setValid = {setValid}
          min_year = {new Date(inputs.dateOfBirth).getFullYear() + 16}
          max_year = {2012}
          help={
            <Fragment>
              <h2>Date Joined Scheme</h2>
              <p>
                If you don't know the exact date when you joined the scheme,
                please provide the approximate date. If you have multiple
                periods of service because you left and then rejoined, please
                provide the earliest joining date, and in the question about
                part time working that will appear below on this page, enter any
                intervening periods during which you were not in service as part
                time periods with the part time proportion being zero. 
              </p>
            </Fragment>
          }
          minValue={
            new Date(
              new Date(inputs.dateOfBirth).setFullYear(
                new Date(inputs.dateOfBirth).getFullYear() + 16
              )
            )
          }
          maxValue={"2012-03-31"}
          minValErrorMsg={
            "Your date of joining must be at least 16 years after your date of birth."
          }
          maxValErrorMsg={
            "Members who joined the scheme on or after 1 April 2012 are not eligible for McCloud remedy. Future versions of this modeller will allow those who joined on or after 1 April 2012 to model their benefits."
          }
          invisible = {
            !move_on_from_dob
          }
        />


       <Input
        input={"status"}
        inputType={"select"}
        options={["Mental Health Officer", "Special Class Officer", "Neither of these"]}
        text={"Let us know if you have Mental Health Officer or Special Class Status:"}
        help={null}
        inputs = {inputs}
        setInputs = {setInputs}
        valid = {valid}
        setValid = {setValid}
        invisible = {
          !(move_on_from_dob &&
            (doj_date < special_status_date)  
          )  }  
    />

      <Input
        input={"MaleNurse"}
        inputType={"select"}
        options={["Yes", "No"]}
        text={"Are you a male nurse?"}
        help={null}
        inputs = {inputs}
        setInputs = {setInputs}
        valid = {valid}
        setValid = {setValid}
        invisible = {
            !(move_on_from_dob &&
              valid.status &&
              (doj_date < new Date("1990-05-17")) &&
              inputs.status === 'Special Class Officer'
              
          )  }  
    />
          {!not_male_nurse_pre_1990 && inputs.MaleNurse && (
        <div className={classes.input_container}>
          <p>
          Male nurses who joined the scheme before 17 May 1990 cannot currently use this modeller.   
          </p>
        </div>
      )}

<Input
        input={"SchemeOptant"}
        inputType={"select"}
        options={["Yes", "No"]}
        text={"Did you choose to move from the 1995 Section to the 2008 Section during the Choice Exercise in 2015? If you’re not sure, select ‘no’."}
        help={null}
        inputs = {inputs}
        setInputs = {setInputs}
        valid = {valid}
        setValid = {setValid}
        invisible = {
          !(move_on_from_dob &&
            not_male_nurse_pre_1990 && 
            valid.dateOfJoining &&
            status_ok &&
            (doj_date < apr_2008)

          ) }  
    />
    {
      (!scheme_optant_ok) && (doj_date < apr_2008) && inputs.SchemeOptant !== undefined &&
      <div className={classes.input_container}>
          <p>
          We are sorry, members who chose to move during the Choice Exercise cannot currently use this modeller. You will be sent a letter explaining your options, including personalised figures, when you apply to retire.      
          </p>
        </div>
    } 

<Input
        input={"BenefitStatement"}
        inputType={"select"}
        options={["Yes", "No"]}
        text={"Do you have your Annual Benefit Statement (ABS) or total reward statement available? "}
        help={null}
        inputs = {inputs}
        setInputs = {setInputs}
        valid = {valid}
        setValid = {setValid}
        invisible = {
          !(move_on_from_dob &&
            status_ok &&
            scheme_optant_ok &&
            not_male_nurse_pre_1990
          )  }  
    />
      
        <Input
          input={"Service2022"}
          inputs = {inputs}
          setInputs = {setInputs}
          valid = {valid}
          setValid = {setValid}
          inputType={"service"}
          text={"How many years and days of reckonable membership did you have on 31/03/2022? You can find this on your ABS."}
         
          minValue={0}
          maxValue={45}
          minValErrorMsg={"The current service you've entered is too low."}
          maxValErrorMsg={"The current service you've entered is too high."}
          invisible = {
            !(move_on_from_dob &&
              valid.dateOfJoining &&
              status_ok &&
                not_male_nurse_pre_1990 &&
              (inputs.BenefitStatement === "Yes") &&
              scheme_optant_ok
              )
          }
        />
        {show_remedy_warning && valid.hasPT && inputs.BenefitStatement === 'Yes' &&
        <div className = {classes.service_too_low}> 
        The minimum service implied by your part-time history is higher than the service
        you have provided above. Please correct your part-time history or service.
        </div>}
        <Input
          input={"salaryCurrent"}
          inputs = {inputs}
          setInputs = {setInputs}
          valid = {valid}
          setValid = {setValid}
          inputType={"pretty"}
          label = {'Current salary'}
          text={"What is your most recent full-time annual pay before tax?"}
          help={
            <Fragment>
              <h2>Current Salary</h2>
              <p>
                This should be pensionable earnings and should be the Full-Time
                Equivalent amount. For example, if you are a part-time worker,
                this means that if your actual pay for part-time working is
                £15,000 per year and you work 20 hours per week of a 37 hour
                week, the Full-Time Equivalent would be £15,000 / 20 = £750.00 x
                37 = £27,750.
              </p>
              <p>
                Please note that final pensionable pay used in this illustrator
                is based on gross pay at the point of retirement which differs
                from the actual final pensionable pay definitions that will be
                used at retirement.
              </p>
            </Fragment>
          }
          minValue={1}
          maxValue={1000000}
          minValErrorMsg={"The current salary you've entered is too low."}
          maxValErrorMsg={"The current salary you've entered is too high."}
          invisible = {
            !(move_on_from_dob &&
              valid.dateOfJoining &&
              valid.BenefitStatement &&
              not_male_nurse_pre_1990 &&
              ((inputs.BenefitStatement === "No") || valid.Service2022)  &&
              scheme_optant_ok
            )
          }
        />
      
        <Input
          input={"salary2015"}
          inputs = {inputs}
          setInputs = {setInputs}
          valid = {valid}
          setValid = {setValid}
          inputType={"pretty"}
          label = {'April 2015 salary'}
          text= {"What was your full-time annual pay before tax on 1 April 2015? If you don’t know, please enter £0."}
          help={
            <Fragment>
              <h2>{params.remedy_start} Salary</h2>
              <p>
                This should be pensionable earnings and should be the Full-Time
                Equivalent amount. For example, if you were a part-time worker,
                this means that if your actual pay for part-time working was
                £15,000 per year and you worked 20 hours per week of a 37 hour
                week, the Full-Time Equivalent would be £15,000 / 20 = £750.00 x
                37 = £27,750.
              </p>
              <p>
                Please note that final pensionable pay used in this illustrator
                is based on gross pay at the point of retirement which differs
                from the actual final pensionable pay definitions that will be
                used at retirement.
              </p>
            </Fragment>
          }
          minValue={0}
          maxValue={1000000}
          minValErrorMsg={`The ${params.remedy_start} salary you've entered is too low.`}
          maxValErrorMsg={`The ${params.remedy_start} salary you've entered is too high.`}
          invisible = {
            !(move_on_from_dob &&
              valid.dateOfJoining &&
              valid.salaryCurrent &&
              valid.BenefitStatement &&
              not_male_nurse_pre_1990 &&
              ((inputs.BenefitStatement === "No") || valid.Service2022) &&
              scheme_optant_ok
            )
          }
        />
      
        <Input
          input={"retirementDate"}
          inputs = {inputs}
          setInputs = {setInputs}
          valid = {valid}
          setValid = {setValid}
          inputType={"date"}
          label = {'Retirement Date'}
          text={"On what date do you plan to retire?"}
          min_year = {retDateLower.getFullYear()}
          min_month = {retDateLower.getMonth()}
          min_day = {retDateLower.getDate()}
          max_year = {max_ret_date.getFullYear()}
          max_month = {max_ret_date.getMonth()}
          max_day = {max_ret_date.getDate()}
          help={
            <Fragment>
              <h2>Your chosen retirement date</h2>
              <p>
              Choose a date in the future before your 75th birthday that comes after whichever is latest of these dates:  
                <ul>
                  <li>Your 55th birthday</li>
                  <li>Today's date </li>
                </ul> 
              </p>
              <p>
                If your chosen retirement date is before your Normal Pension Age (NPA), warning alerts will pop up. 
                This is because your NPA is the earliest you can take your pension benefits without any reductions. 
              </p>
              {ret_help_message}
            </Fragment>
          }
          minValErrorMsg={`The retirement date cannot be less than ${formatDateObject(retDateLower)}.`}
          maxValErrorMsg={ inputs.spa_age ? `The retirement age cannot be greater than your state pension age, which is ${inputs.spa_age['years']} years and ${inputs.spa_age['months']} months.` : null}
          invisible = {
            !(move_on_from_dob &&
              valid.dateOfJoining &&
              valid.salaryCurrent &&
              valid.salary2015 &&
              valid.BenefitStatement &&
              not_male_nurse_pre_1990 &&
              ((inputs.BenefitStatement === "No") || valid.Service2022) &&
              scheme_optant_ok
            )
          }
        />
      <InputRetAgeWarning
        scheme = {inputsRef.current.legacyScheme}
        status = {inputs.status}
        spa_date = {inputs.spa_date}
        spa_months = {spa_months}
        spa_years = {spa_years}
        ret_date = {inputs.retirementDate}
        reformed_npa_date = {reformed_npa_date}
        legacy_npa_date = {legacy_npa_date}
        legacy_npa = {legacy_npa}
        dob = {birthday}
        invisible = {
          !(move_on_from_dob &&
            valid.dateOfJoining &&
            valid.salaryCurrent &&
            valid.salary2015 &&
            valid.BenefitStatement &&
           not_male_nurse_pre_1990 &&
            ((inputs.BenefitStatement === "No") || valid.Service2022) &&
            scheme_optant_ok
          )
        }
        />

      
        <Input
          input={"hasPT"}
          inputs = {inputs}
          setInputs = {setInputs}
          valid = {valid}
          setValid = {setValid}
          inputType={"select"}
          options={["Yes", "No"]}
          text={"Have you ever worked part time " + (min_pt_year < 2014 ? 'before' : ('since 1st April ' + min_pt_year)) + "?"}
          help={null}
          invisible = {
            !(move_on_from_dob &&
              valid.dateOfJoining &&
              valid.salaryCurrent &&
              valid.salary2015 &&
              valid.retirementDate &&
              valid.BenefitStatement &&
              not_male_nurse_pre_1990 &&
              ((inputs.BenefitStatement === "No") || valid.Service2022) &&
              scheme_optant_ok
            )
          }
        />



    <PartTimeServiceInput2 
      setInputs = {setInputs}
      set_show_remedy_warning = {set_show_remedy_warning}
      left_margin= {'10px'}
      min_year = {
        min_pt_year
      }
      max_year = {(new Date(inputs.dateOfBirth)).getFullYear() + 75}
      min_date = {min_pt_date}
      visible = {
       move_on_from_dob &&
      valid.dateOfJoining &&
      valid.salaryCurrent &&
      valid.salary2015 &&
      valid.retirementDate &&
      not_male_nurse_pre_1990 &&
      ((inputs.BenefitStatement === "No") || valid.Service2022)  &&
      inputs.hasPT === "Yes" &&
      scheme_optant_ok
      } />

      {
      (!show_remedy_warning) &&
      move_on_from_dob &&
      valid.dateOfJoining &&
      valid.salaryCurrent &&
      valid.salary2015 &&
      valid.retirementDate &&
      valid.hasPT &&
      valid.BenefitStatement &&
      scheme_optant_ok &&
      not_male_nurse_pre_1990 ? (
        <div className={classes.button_container}>
          <CalculateButton />
        </div>
      ) : null}

      {show_remedy_warning && valid.hasPT && 
      inputs.BenefitStatement === 'Yes' &&
      <div className = {classes.service_too_low}> 
        The minimum service implied by your part-time history is higher than the service
        you have provided above. Please correct your part-time history or service.
        </div>}
    </div>
  );
}

export default YourDetailsPage;
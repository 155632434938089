import React, { createContext, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/0-Layout";
import HomePage from "./pages/1-Home";
import YourDetailsPage from "./pages/2-YourDetails";
import ShowDetail from "./pages/3-ShowDetail";
import TACPage from "./pages/4-TAC";
import SimpleView from "./pages/5-SimpleView";
import params from './global_parameters' 
import { InputDataContext } from "./shared/contexts/InputDataContext";
import ConsentPage from "./pages/ConsentPage";


export const OutputDataContext = createContext({});

function App() {
  Object.freeze(params); /* make it impossible to modify parameters' */
  
  const scrollPositions = {
    'dateOfBirth' : useRef({}), 
    'dateOfJoining' : useRef({}), 
    'dateLeftScheme': useRef({}),
    'retirementDate' : useRef({})
  }

  const inputsRef = useRef({

    partTimeService: [],
    salaryIncrease: 2,
    selected_CP_lump_sum_choose_care: 0,
    selected_CP_lump_sum_choose_legacy: 0,
    selected_LP_lump_sum_choose_care: 0,
    selected_LP_lump_sum_choose_legacy : 0, 
    equalise : 'unequal', 
    inflation : 'nominal', 
    salaryCurrent : 'xxx', //we use xxx to denote that the user has not supplied an input yet
    salary2015 : 'xxx', 
    serviceYears : 'xxx',
    serviceDays : 'xxx', 
  });

  const validRef = useRef({
    inReceipt: false,
    hasLeft: false,
    dateLeftScheme: false,
    legacyScheme: false,
    dateOfBirth: false,
    dateOfJoining: false,
    salaryCurrent: false,
    salary2015: false,
    retirementDate: false,
    hasPT: false,
  });

  const outputDataRef = useRef([]);

  return (
    <InputDataContext.Provider value={{ inputsRef, validRef,scrollPositions}}>
      <OutputDataContext.Provider value={{ outputDataRef }}>
        <Layout>
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/yourdetails" element={<YourDetailsPage />} />
            <Route path="/ConsentPage" element={<ConsentPage />} />
            <Route path="/simple-view" element={<SimpleView />} />
            <Route path="/show-detail" element={<ShowDetail />} />
            <Route path="/T&Cs" element={<TACPage />} />
          </Routes>
        </Layout>
      </OutputDataContext.Provider>
    </InputDataContext.Provider>
  );
}


export default App;



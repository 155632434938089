import React, { useState, useContext, useRef, useEffect } from "react";
import InfoModal from "../../general/InfoModal";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import "react-datepicker/dist/react-datepicker.css";
import FullDateDropdown from './FullDateDropdown'
import MultipleChoice from "./MultipleChoice";
import PrettyTextInput from './PrettyTextInput'
import ServiceInput from "./ServiceInput";
import Warning from "../../../assets/images/warning.png"

import classes from "./Inputs.module.css";
import { poundToNumber } from "../../../utilities/Formatters";

const Input = (props) => {
  
  const { scrollPositions} = useContext(InputDataContext);
  const inputs = props.inputs
  const setInputs = props.setInputs
  const valid = props.valid
  const setValid = props.setValid
  const need_currency = props.input === 'salary2015' | props.input === 'salaryCurrent'

  const errorTimeoutRef = useRef(null)
  const validTimeoutRef = useRef(null)


  

  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (error) {
        setShowError(true);
    }
    if (!error) {
      setShowError(false);
    }
  }, [error]);


  function debounce(func, wait, name_of_fun, timeoutRef) {

    // Enhanced function to allow cancelation
    const debouncedFunction = function(...args) {
        const later = () => {
            clearTimeout(timeoutRef.current);
            
            func.apply(this, args);
        };

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(later, wait);
    };

    return debouncedFunction;
  }

  const debouncedSetError = debounce(setError, 150, 'setError', errorTimeoutRef)
  const debouncedSetValid = debounce(setValid, 150, 'setValid', validTimeoutRef)

  const changeHandler = (event) => {

    var val = need_currency ? poundToNumber(event.target.value) : event.target.value

    if(props.input === 'retirementDate') {
        var extra_input = {'stored_ret_date': val} 
    } else {
        var extra_input = {}
    }
    setInputs({ ...inputs, [props.input]: val, ...extra_input });

    if(['PAR', 'inReceipt', 'Practitioner', 'MaleNurse'].includes(props.input)) {
      if(val === 'Yes') {
        debouncedSetValid((prev) => {return({...prev, [props.input] : false})})
        return;
      }
    }

    if (props.inputType === "number" | props.inputType === "pretty") {
      
     
      var userInput = Number(val)
      
      let minValue = props.minValue;
      let maxValue = props.maxValue;

      if (userInput < minValue) {
        debouncedSetError(props.minValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }


      if (userInput > maxValue) {
        debouncedSetError(props.maxValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }

    }

    if (props.inputType === "date") {
      let userInput = new Date(event.target.value);
      let dateMinValue = new Date(props.minValue);
      let dateMaxValue = new Date(props.maxValue);

      if (userInput < dateMinValue) {
        debouncedSetError(props.minValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }

      if (userInput > dateMaxValue) {
        debouncedSetError(props.maxValErrorMsg);
        debouncedSetValid({ ...valid, [props.input]: false });
        return;
      }

      
    }

    
    clearTimeout(validTimeoutRef.current)
    setValid({ ...valid, [props.input]: true });
    clearTimeout(errorTimeoutRef.current)
    setError(null);
  };

  const infoMessage = props.help;



  return (

      <div className={classes.input_container + (props.invisible ?  (' ' + classes.invisible) : '' )}
      style = {{'--box-width' : '27%', '--left-margin' : '5px'}}
      >
        {showModal && (
          <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
        )}
        <section>
          <p>{props.text}</p>
          {
          props.input === "retirementDate" && 
          <img 
          className = {classes.modal_warning_image}
          src = {Warning} 
          alt = 'Warning symbol' /> 
          }
          {infoMessage && <button
          className = {
            classes.modal_button + (
              props.input === "retirementDate" ? 
              ' ' + classes.important_warning :
              ''
            )
          }
          onClick={modalHandler}>Help</button>}
        </section>
        {props.inputType === "select" && (
          
          <MultipleChoice
          options = {props.options}
          changeHandler = {changeHandler}
          chosen = {(inputs[props.input] === undefined ) ? '' : inputs[props.input]}
          input = {props.input}
          />
        )}
        {props.inputType === "date" && (
          
          <FullDateDropdown
          existing_value = {(inputs[props.input] === undefined ) ? '' : inputs[props.input]}
          onChange = {changeHandler}
          scrollPositions = {scrollPositions[props.input]}
          min_year = {props.min_year}
          min_month = {props.min_month}
          min_day = {props.min_day}
          max_year = {props.max_year}
          max_month = {props.max_month}
          max_day = {props.max_day}
          go_red = {showError && error}
          />
        )}
        {props.inputType === "number" && (
          <input
          value ={(inputs[props.input] === undefined) ? '' : inputs[props.input]}
            type="number"
            onChange={changeHandler}
            min={(isNaN(props.minValue) | (props.minValue === undefined)) ? '' : props.minValue}
            step={1}
            max={props.maxValue === NaN ? '' : props.maxValue}
          />
        )}
        {props.inputType === "pretty" && (
          <PrettyTextInput
          type = {need_currency ? 'currency' : 'number'}
          label = {props.label}
          value ={inputs[props.input]}
          onChange = {changeHandler}
          showError = {showError}
          error = {error}

          />
        )}

          {props.inputType === 'service' && (
            <ServiceInput
            onChangeYear = {
              (event) => {
                var extra = {}
                if(Number(event.target.value) === 45) {
                  extra = {'serviceDays' : 0}
                }
                props.setInputs(
                  {...props.inputs, 'serviceYears' : Number(event.target.value), ...extra}
                )

                let userInput = Number(event.target.value);
                let minValue = props.minValue;
                let maxValue = props.maxValue;
          
                if (userInput <= minValue && props.inputs.serviceDays === 0) { 
                  debouncedSetError(props.minValErrorMsg);
                  debouncedSetValid({ ...valid, 'Service2022': false });
                  return;
                }
          
                if (userInput > maxValue) {
                  debouncedSetError(props.maxValErrorMsg);
                  debouncedSetValid({ ...valid, 'Service2022': false });
                  return;
                }
                
                clearTimeout(errorTimeoutRef.current)
                setError(null)
                clearTimeout(validTimeoutRef.current)
                setValid({...valid, 'Service2022' : true})

              }
            }
            onChangeDay = {
              (event) => {
                var val = Number(event.target.value)
                val = Math.max(val, 0)
                val = Math.min(val, 365)
                if(props.inputs.serviceYears === 45) {
                  val = 0
                }
                props.setInputs(
                  {...props.inputs, 'serviceDays' : val}
                )
              }
            }
            years = {props.inputs.serviceYears}
            days = {props.inputs.serviceDays}
            error = {error}
            />
          )}

         <p className={classes.ErrorMsg + (
          (showError && error) ? '' : (' ' + classes.invisible)
         )}
         
         >{error}</p>

      </div>

  );
};

export default Input;

import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import classes from "./TermsModal.module.css";

const Backdrop = () => {
  return <div className={classes.background} />;
};

const ModalOverlay = (props) => {
  const [password, set_password] = useState('')



  return (
    <div className={classes.modal}>
      <h2>Before you start</h2>
      <p>
      For the best experience, use this tool on a laptop or desktop computer.
      </p>
      <p> 
      It’s important to have your Annual Benefit Statement (ABS) to hand if you can. This will make sure your 
      estimate is as accurate as it can be and means as many members as possible can use this tool.
      </p>
      <p>
      You can find your ABS by logging into ESR if you use it – your ABS forms part of your Total Reward Statement. 
      Or you can find your ABS by logging into My NHS Pension. If you can’t access your ABS, it’s still possible to 
      use this tool to give you a general picture, but be aware the figures will be less accurate. 
      </p>

      <p> To use this tool, enter the password below</p>
      <div className = {classes.password_and_button_container}> 
      <input 
      type = 'password' 
      value = {password}
      onChange = {(e) => set_password(e.target.value)}
      />
      {password === 'GAD001' && 
      <button
        className={`${classes.btn_main} ${
         classes.btn_agreed
        }`}
        onClick={props.onClick}
      >
        Continue to the Remedy Benefits Illustrator
      </button>
      }
      </div>
    </div>
  );
};

const TermsModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay onClick={props.onClick} />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default TermsModal;

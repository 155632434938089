import React from "react";

import TextBlock from "../components/general/text/TextBlock";
import { TCText } from "../assets/data/TextBlocks";
import TestInflationSalary from "../components/results/TestInflationSalary";

function TACPage() {
  const show_checks_development = false
  const show_checks = show_checks_development && (window.HOST_URL.includes("gad-hosting") || window.HOST_URL.includes("localhost"))
  return (
    <div id="T&Cs_Page">
      <TextBlock blocks={TCText}></TextBlock>
      {show_checks && <TestInflationSalary type = 'inflation'/>}
      {show_checks && <TestInflationSalary type = 'salary'/>}
    </div>
  );
}

export default TACPage;

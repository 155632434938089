export const spaAPI = async (dateOfBirth, inputs, setInputs) => {
  let address =
    (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "/api/getspa";

    console.log('address', address)
  const requestOptions = {
    method: "Post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ DOB : dateOfBirth }),
  };

  fetch(address, requestOptions)
    .then((response) => {return(response.json())})
    .then((json) => {setInputs(
      { ...inputs,
         spa: json["SPA"],
       spa_age : json['SPA_age'],
        'reformed_npa_date' : json['reformed_npa_date'] }
    )})
    .catch((error) => console.log(error));
};

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./shared/utilities/ScrollToTop";
import App from "./App";
import "./index.css";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-58X4BB5",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

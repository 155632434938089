import React, { Fragment, useContext, useRef, useState } from "react";
import PrettyTextInput from "./PrettyTextInput";

function ServiceInput(props) {
    return(
        <div>
            <PrettyTextInput
            type = 'text'
            label = 'years'
            value = {props.years}
            onChange = {props.onChangeYear}
            showError = {props.error !== null}
            error = {props.error}
            />
            <PrettyTextInput
            type = 'text'
            label = 'days'
            value = {props.days}
            onChange = {props.onChangeDay}
            showError = {false}
            />
        </div>
    )
}

export default ServiceInput
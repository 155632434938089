import React, { useState } from 'react';    
import { useNavigate, Link } from 'react-router-dom';      
import classes from "./2-YourDetails.module.css";  
import classes2 from "../components/yourdetails/CalculateButton.module.css"
import FancyToggle from "../components/results/switches/FancyToggle"
      
function ConsentPage() {      
  const navigate = useNavigate();  
  const [isChecked, setIsChecked] = useState(false);  
  const [button_clicked, set_button_clicked] = useState(false)    
  
  const navigateToInputForm = () => { 
    set_button_clicked(true)
    if(isChecked) {
      navigate('/yourdetails'); 
    }     
         
  }  
  
  const handleCheckboxChange = () => {   
    setIsChecked(!isChecked);  
  }  
      
  return (      
    <div>      
      <h2>Extra information and declaration</h2>      
      <p>      
        <b>This tool won’t check your information against other systems and won’t store any data you put into it.</b> 
      </p>
      <p>
        This tool is for guidance to help you understand how your total pension benefits are affected by your remedy choice. 
        The figures are projected benefits, based on the information you put in, so they won’t be the same as those in your Annual Benefit Statement. 
      </p>      
      <p>      
        <b>The figures aren’t your final retirement benefit offer and can’t be used as financial advice.  </b>
      </p>
      <p>
      For most members, the choice is straightforward. A small number of members with complex circumstances may 
      find they need independent financial advice to help them make their choice. These members may be able to
       claim back the cost of this advice by applying for the <a href="https://www.nhsbsa.nhs.uk/public-service-pensions-remedy-mccloud/nhs-cost-claim-back-scheme"  target = "_blank">Cost Claim Back Scheme</a>. 
        You’ll need to make this choice when you apply for your pension benefits.  
      </p>  
      <p>
        If you’d like to speak to an independent financial adviser, you can find one at <a href="https://www.unbiased.co.uk/" target = "_blank">unbiased.co.uk</a>.
      </p>    
      <p> <b>The assumptions we have used are: </b></p>      
      <table>     
        <tbody> 
        <tr>      
          <td>Inflation</td>      
          <td>Actual CPI used up to today’s date, 2% per year for future projections </td>      
        </tr>      
        <tr>      
          <td>General pay increases</td>      
          <td>2% per year</td>      
        </tr>  
        </tbody>     
      </table>      
      <p>      
      The projected benefits in this illustrator are intended to reflect the £ amounts that will be paid out at retirement. This means they have not been adjusted for future inflation and so do not represent benefits in “today's money”.     
      </p>
      <p>
        There are some things not covered by this illustrator, like what your decision means for your tax position. The majority of members are not 
        likely to see a change to their tax position, but you can check using HMRC’s dedicated PSPR guidance and calculator 
        at <a href="https://www.gov.uk/guidance/calculate-your-public-service-pension-adjustment">https://www.gov.uk/guidance/calculate-your-public-service-pension-adjustment.</a> 
      </p>
      <p>
        You can see a full list of exclusions for this illustrator at <span style = {{'color': 'red'}}>CLIENT TO PROVIDE HOSTING URL</span>.
      </p>
      <h2>Declaration</h2>
      <p>By using this illustrator, you agree that you have read, understood, and agree to the Terms & Conditions set out in the 
        <Link to = "/T&Cs"> T&Cs' tab </Link>
        .
      </p>      
      <div className = {classes2.fancy_toggle_container}> 
      <FancyToggle state = {isChecked} handleChange = {handleCheckboxChange} showInflationSwitch = {true}/>    
      <p style = {{'color' : isChecked ? 'black' : 'grey', 'fontWeight' : isChecked ? '700' :'600'}}>I have read, understand, and agree to the terms of service.</p>
      </div>
      <br/>      
        
      <div className={classes.button_container}>  
        <button className={classes2.pagebutton} onClick={navigateToInputForm}>  
          Continue to the Pension Estimate Tool  
        </button>  
        
      </div>   
      {!isChecked && button_clicked && <p className = {classes2.warning_message}> You must agree to the terms of service before you can proceed</p>}
    </div>      
  );      
}     
    
export default ConsentPage;  

import React, {useState, Fragment} from 'react';   
import Warning from '../../../assets/images/warning.png'
import classes from './InputRetAgeWarning.module.css'
import InfoModal from '../../general/InfoModal'
import { moveDateByYears, getLatestDate } from '../../../utilities/Formatters';

const InputRetAgeWarning = (props) => {
    
    const [show_modal, set_show_modal] = useState(false)
    const ret_date = new Date(props.ret_date)
    
    if(props.spa_years && props.ret_date) {
        const legacy_scheme = props.scheme
        const legacy_npa = props.legacy_npa
        const legacy_npa_date = props.legacy_npa_date
        const reformed_npa_date = props.reformed_npa_date
        

        var message 

        if(legacy_npa_date > ret_date) {
            message = 'WARNING: The retirement date you\'ve chosen is earlier than your Normal Pension Ages (NPAs) for both the ' + legacy_scheme + ' and the 2015 Scheme. This means your pension benefits will be reduced for early payment, because you\’ll receive them for longer.'
        } else if(reformed_npa_date > ret_date) {
            message = 'WARNING: The retirement date you\'ve chosen is earlier than your Normal Pension Age (NPA) for the 2015 Scheme. This means your pension benefits will be reduced for early payment, because you\’ll receive them for longer.'
        } else {
            message = ''
        }

        var modal_message = <Fragment> 
            <h1> Implications of early retirement </h1>
            <p> 
            Each part of the Scheme has a different normal pension age, or NPA. 
            This is the earliest age you can claim your benefits without any reductions. 
            </p>
            <p> Your NPA for your {legacy_scheme} benefits is age {legacy_npa} </p> 
            <p> In the 2015 Scheme, 
            it’s the same as your state pension age, which is {props.spa_years} years 
            and {props.spa_months} months. </p>
            <p>
             Any benefits you take before your NPA will be reduced because you will receive them for longer.
            You don’t have to take all your benefits at the same time. 
            On your detailed results page, you can see how your retirement age might affect the value of your benefits. 
        </p>
        </Fragment>
    } else {
        var message = ''
        var modal_message = null
    }
    



    return(
        <div className = {classes.main_container + ' ' + (props.invisible || props.ret_date === undefined || message === '' ? classes.invisible : '')} > 
            <div className = {classes.sub_container} onClick = {() => { set_show_modal(true)}}> 
                <div className = {classes.img_container}> 
                    <img src = {Warning} alt = 'Warning symbol'/>
                </div>
                <p> {message}  </p>
            </div>
            {show_modal && <InfoModal
            infoMessage = {modal_message}
            modalHandler = {() => { set_show_modal(false)}}
            />}
        </div>
    )
}

export default InputRetAgeWarning
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import params from "../../global_parameters";

export const TCText = [
  {
    id: "T&Cs",
    text: (
      <Fragment>
        <h1>Terms and Conditions</h1>
        <p>
          By using the illustrator, you agree you have read, understood, and agreed to the Terms & Conditions set out below.
        </p>
        <h2>1. Use of this illustrator</h2>
        <p>
          The illustrator is solely for guidance purposes and provides estimates
          only. It is not intended to provide financial advice. If you require
          financial advice, you should contact an independent financial adviser.
          The Government Actuary’s Department (GAD) and the NHS Pension Scheme 
          and its administrators do not accept any liability for
          financial decisions taken based on the information provided in the
          illustrator.
        </p>
        <p>
          Members may be able to claim back the cost of advice by applying 
          for the <a href="https://www.nhsbsa.nhs.uk/public-service-pensions-remedy-mccloud/nhs-cost-claim-back-scheme" target = "_blank">Cost Claim Back Scheme</a>.
        </p>
        <p>
          The results shown are an indication of the benefits you may receive;
          they are not guaranteed and you should not enter into financial
          commitments on the basis of the information provided.
        </p>
        <p>
          The illustrator does not override the scheme rules or pension
          legislation.
        </p>
        
        <h2>2. How your pension and lump sum have been estimated</h2>
        <h3>
          2.1. Choice of benefits accrued over 1 April {params.remedy_start} to
          31 March 2022
        </h3>
        <p>
        The results section shows a comparison of pension and lump sum benefits that 
        might be received if you choose either Option A (Standard Benefits) or Option B
        (Alternative Benefits) for the remedy period, which is from 1 April 2015 to 
        31 March 2022. Member contributions are not included in the figures.
        </p>
        <h3>2.2. Assumptions</h3>
        <p>
          The pension and lump sum estimates are based on the following
          key assumptions:
        </p>
        <ul>
          <li>Actual Consumer Price Inflation (CPI) is applied up to today’s date </li>
          <li>Consumer Price Inflation (CPI) is assumed to be 2% a year for future projections </li>
          <li>
            Your total 2015 Scheme pension is revalued in line with prices (CPI) + 1.5% 
            every year that you are an active member of the 2015 Scheme. 
          </li>
          <li>Salaries are assumed to increase at a rate of 2% per year</li>
        </ul>
        <h3>2.3. Adjustments for early or late payment of pension</h3>
        <p>The amount of pension you receive depends on when you retire.</p>
        <p>
          Claiming your pension earlier than your Normal Pension Age (NPA) means it will be 
          permanently reduced because it will be paid to you for longer.
        </p>
        <p>
          Each part of the NHS Pension Scheme has a different NPA. This illustrator applies 
          adjustments when pension and lump sum are taken earlier or later than the relevant part of the 
          scheme’s (NPA), in line with the Scheme rules. Allowance for late retirement factors 
          has been made for members retiring after their NPA in the 2008 Section and 2015 Scheme. 
          There are no late retirement factors applicable to the 1995 Section. 
        </p>
        <p>
          The early and late retirement adjustment factors used in this illustrator are based on the factors 
          currently used by the NHS Pension Scheme. These will be reviewed from time to time and may increase 
          or decrease. Your benefits at retirement will depend on any adjustment factors at the time.
        </p>
        <h3>2.4. Cash lump sum</h3>
        <p>
          At retirement, you will get an automatic lump sum from service in the 1995/2008 Scheme. This is 
          usually three times your pension (prior to any adjustment, if applicable). You are eligible to 
          permanently reduce your pension in exchange for a one-off lump sum from all of the NHS pension 
          schemes – this is called commutation. You can change the assumed lump sum on the 
          'Show Detail' page.
        </p>
        <h2>3. Taxation</h2>
        <p>
          The illustrations do not allow for taxation. Your benefits will be
          subject to the various tax rates and limits in force.
        </p>
        <p style = {{'color' :'red'}}> PLACEHOLDER FOR .GOV LINK</p>
        <h2> 4. Other</h2>


<p> 
Your Scheme provides survivor benefits payable in the event of your death, which are not shown here. See your Scheme guide for details. Your benefit statement may also include more about this.
</p>

<p> 
For Mental Health Officers, the illustrator makes allowance for double accrual and Uniform Accrual Formulae in a necessarily approximate way and may not fully reflect all circumstances.
</p>

<p> 
It has been assumed the normal retirement age for Mental Health Officers is age 55. 
</p>

      </Fragment>
    ),
  },
];

export const SmallPrintText = [
  {
    id: "SP",
    text: (
      <Fragment>
        <h2>How to use this calculator</h2>
        <p>
        It will not check your information against pension or other systems,
        so if you enter inaccurate information, the figures you get will be 
        of limited value. No data you enter into this calculator is stored 
        or processed.
        </p>
        <p>
          By using this calculator, you agree that you have read
          and understood the Terms & Conditions as set out in the{" "}
          <Link to="/T&Cs">T&Cs' tab</Link>.
        </p>
        <p>
          This calculator is for guidance and illustration - 
          it will give you a basic illustration of your pension benefits 
          in the 2015 Scheme and your legacy scheme in two scenarios: 
          Option A (Standard Benefits) and Option B (Alternative Benefits). 
          Things such as tax debits are not taken into account. If you have these, 
          or other additional options, the figures you get may be less accurate, 
          but still helpful. The outputs of the calculator are not financial 
          advice - if you’d like to speak to an independent financial adviser 
          you can find one at <a href="https://www.unbiased.co.uk/" target = "_blank">unbiased.co.uk</a>.
        </p>
        <p style = {{'fontWeight' : 'bold'}}>The assumptions we have used are:</p>
        <table>
          <tbody>
            <tr>
              <td>Consumer Price Index</td>
              <td>Real values for past inflation; an annual rate of 2% for future inflation</td>
            </tr>
            <tr>
              <td>Earnings inflation</td>
              <td>Equal to Consumer Price Inflation, i.e., real salary growth of 0%</td>
            </tr>
          </tbody>
        </table>
        <p>
        The figures output by the calculator are projected benefits and will not
         be the same as those in your Annual Benefit Statement.
        </p>
        <p>
        The projected benefits in this calculator are in “today's money” – 
        this means the values are adjusted for inflation and represent what 
        the pension would be worth today, so you can compare it with your 
        current earnings and the amount you think you’ll need in retirement.
        </p>
      </Fragment>
    ),
  },
];

export const IntroText = [
  {
    id: "IT",
    text: (
      <Fragment>
        <h2>Welcome to the NHS Pensions Remedy Benefits Illustrator</h2>
        <p>
          On 1 April 2015, the government made changes to public service pension 
          schemes which treated members differently based on their age. To put 
          right this age discrimination, affected members will be asked to choose 
          the benefits that are best for them. 
        </p>
        <p>
          This is known as the Public Service Pension Schemes Remedy (PSPR), 
          sometimes called the McCloud remedy. 
        </p>
        <p>
          The PSPR means that affected members have a choice between receiving 
          1995/2008 Scheme or 2015 Scheme benefits for their membership during 
          the remedy period, which is between <b>1 April 2015 and 31 March 2022</b>. 
          You’ll need to make this choice when you apply for your pension benefits. 
        </p>
        <br></br>
        <h2>This illustrator is designed to help you make your remedy choice</h2>
        <p>
          This tool shows you how much your total pension benefits are worth 
          depending on which part of the Scheme you choose for your remedy period 
          membership. You have two options to choose from:
        </p>
        <p>
          <b> Option A: Standard benefits</b>
        </p>
        <p>
        1995/2008 Scheme benefits for your membership up to 1 April 2015,  
          <br></br>
          plus <b> 1995/2008 Scheme benefits for the remedy period, </b>
          <br></br>
          plus 2015 Scheme benefits from 1 April 2022. 
        </p>
        
        <p> 
         <b> Option B: Alternative benefits.  </b>
        </p> 
        <p>
          1995/2008 Scheme benefits for your membership up to 1 April 2015, 
          <br></br>
          plus <b> 2015 Scheme benefits for the remedy period,  </b>
          <br></br>
          plus 2015 Scheme benefits from 1 April 2022. 
        </p> 

        <p>
          Both schemes offer highly valuable benefits, but there are some differences. 
          Using this tool can help you to understand how different choices, like your 
          retirement age or the amount of lump sum you want to take, may affect your 
          overall pension benefits.
        </p>
        <p>
          For the majority of affected members, their remedy choice will be straightforward. 
          A small number of members with complex circumstances may find they need independent 
          financial advice to help them make their choice. These members may be able to claim 
          back the cost of this advice by applying for 
          the <a href="https://www.nhsbsa.nhs.uk/public-service-pensions-remedy-mccloud/nhs-cost-claim-back-scheme" target="_blank">
          Cost Claim Back Scheme</a>. 
        </p>
      </Fragment>
    ),
  },
];

export function textToPercent(x) {
    return x === "" ? "" : x + "%";
  }

export function processPercentDelete(x, y) {
// Check if y is the same as x with a '%' appended
if (y === x + "%") {
    // Return y with its penultimate character removed
    return y.slice(0, -2) + y.slice(-1);
}
// If the condition is not met, return x
return x;
}

export function sanitisePercentString(str) {
// 1. Remove any character that is not an arabic numeral, a decimal point, or percentage
let cleaned = str.replace(/[^0-9.%.]/g, "");

// 2. Handle decimal points
if (cleaned.charAt(0) === ".") {
    // Remove if the first character is a decimal point
    cleaned = cleaned.substring(1);
}

let firstDecimalPointIndex = cleaned.indexOf(".");
if (firstDecimalPointIndex !== -1) {
    // Remove any subsequent decimal points
    cleaned =
    cleaned.substring(0, firstDecimalPointIndex + 1) +
    cleaned.substring(firstDecimalPointIndex + 1).replace(/\./g, "");
}

// 4. Ensure no more than two characters after the decimal point
firstDecimalPointIndex = cleaned.indexOf(".");
if (
    firstDecimalPointIndex !== -1 &&
    firstDecimalPointIndex + 3 < cleaned.length
) {
    cleaned = cleaned.substring(0, firstDecimalPointIndex + 3);
}

return cleaned;
}
import React, { useState, Fragment, useEffect } from "react";
import { currencyFormatter, sumValues } from "../../shared/utilities/Formatters";
import classes from "./ComparisonTable.module.css";
import params from "../../shared/utilities/global_parameters";

const ComparisonTable = (props) => {
  const equalise = props.outputData.equalise
  //capitalise first letter
  function cfl(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  const inflation_relevant = props.outputData.exact_age < props.outputData.retirementAge


 
  const renderExtraRows = (x, index) => {
    // Destructure the 'classic' and 'alpha' objects from x
    const { classic, alpha } = x;
  
    // Function to generate a table row from an object
    const generateRow = (data, include_span) => {
      return (
        <tr className = {classes.extra_row}>
          {Object.keys(data).map((key) => {
            if((key === 'Lump Sum') && include_span) {
              return(
              <td key={key} className = {classes.lump_sum_td}>
                {data[key]} 
                <button
                onClick = {() => {
                  
                  var prev = saved_scenarios
                  var prev = prev.filter((value, i) => i !== index )
                  set_saved_scenarios(prev)

                }}
                > delete</button>
                </td>
              )
            } else {
            return(<td key={key}>{data[key]}</td>)
            }
        })}
        </tr>
      );
    };
  
    return (
      <Fragment>
        {generateRow(classic, true)}
        {generateRow(alpha, false)}
      </Fragment>
    );
  };

  const [saved_scenarios, set_saved_scenarios] = useState([])

  useEffect(
    () => {props.saved_scenarios_ref.current = saved_scenarios}, 
    [saved_scenarios]
  )

  const push_saved_scenario = () => {
    var prev = [...saved_scenarios]
    prev.push(
      {'classic' : {
        'Retirement Age' : props.outputData.retirementAge, 
        'Salary Increase' : props.outputData.salaryIncrease + '%', 
       
        'Scheme' : 'Option A',
        [props.legacySchemeName + ' pension'] : currencyFormatter(props.pension.choose_legacy.old), 
        [props.remedySchemeName + ' pension'] :  currencyFormatter(props.pension.choose_legacy.new), 
        'Total Pension' : currencyFormatter(
          sumValues([
            props.pension.choose_legacy.old,
            props.pension.choose_legacy.new,
          ])
        ), 
        'Lump Sum' : currencyFormatter(props.lump_sum["choose_legacy"])
      },
    'alpha' : {
      'Retirement Age' : props.outputData.retirementAge, 
        'Salary Increase' : props.outputData.salaryIncrease + '%', 
       
        'Scheme' : 'Option B', 
        [props.legacySchemeName + ' pension'] : currencyFormatter(props.pension.choose_reformed.old), 
        [props.remedySchemeName + ' pension'] :  currencyFormatter(props.pension.choose_reformed.new), 
        'Total Pension' : currencyFormatter(
          sumValues([
            props.pension.choose_reformed.old,
            props.pension.choose_reformed.new
          ])
        ), 
        'Lump Sum' : currencyFormatter(props.lump_sum["choose_reformed"])
    }}
    )
    set_saved_scenarios(prev)
  }

  return (
    <div className={classes.container} >
      <h2>Your Comparison Table</h2>
      <p>
        You can use this table to compare the expected benefits in the scenarios
        outlined above.
      </p>
      <table className={classes.comp_table} id = {'comparison_table'}>
      <thead>
        <tr>
          <th>Retirement Age</th>
          <th>Salary Increase</th>
          <th>Option</th>
          <th>{props.legacySchemeName} pension</th>
          <th>{props.remedySchemeName} pension</th>
          <th>Total Pension</th>
          <th>Lump Sum</th>
        </tr>
      </thead>
        <tbody>
          <tr>
            <td>{props.outputData.retirementAge}</td>
            <td >{props.outputData.salaryIncrease}%</td>
            

            <td> Option A </td>
            <td>
              {currencyFormatter(props.pension.choose_legacy.old)}
            </td>
            <td>
              {currencyFormatter(props.pension.choose_legacy.new)}
            </td>
            <td>
              {currencyFormatter(
                sumValues([
                  props.pension.choose_legacy.old,
                  props.pension.choose_legacy.new,
                ])
              )}
            </td>
            
              <td className = {classes.lump_sum_td}>
                {currencyFormatter(props.lump_sum["choose_legacy"])}
              </td>
            
          </tr>
          <tr>
            <td>{props.outputData.retirementAge}</td>
            <td >{props.outputData.salaryIncrease}%</td>
            
            <td> Option B </td>

            <td>
              {currencyFormatter(props.pension.choose_reformed.old)}
            </td>
            <td>
              {currencyFormatter(props.pension.choose_reformed.new)}
            </td>
            <td>
              {currencyFormatter(
                sumValues([
                  props.pension.choose_reformed.old,
                  props.pension.choose_reformed.new,
                ])
              )}
            </td>
         
              <td>
                {currencyFormatter(props.lump_sum["choose_reformed"])}
              </td>
            
          </tr>
          {
            saved_scenarios.map((x, index) => {return(renderExtraRows(x, index)) })
          }
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;

import classes from './RetirementAgeWarning.module.css'
import React, {useState, Fragment } from "react";
import Image from "../../assets/images/warning.png"
import InfoModal from "../../components/general/InfoModal";
import { setToStartOfDay } from '../../utilities/Formatters';

const RetirementAgeWarning = (props) => {

    const [showModal, setShowModal] = useState('')

    const clean_spa_age = props.spa_years + (
        props.spa_months > 0 ? ' years and ' + props.spa_months + ' months' : ''
    )
    const numeric_spa = props.spa_years + props.spa_months /12

    const modalHandler = () => {setShowModal('')} 

    const retirementDate = setToStartOfDay(new Date(props.retirementDate))
    const legacy_npa_date = setToStartOfDay(props.legacy_npa_date)
    const reformed_npa_date = setToStartOfDay(props.reformed_npa_date)

    const infoMessage = {
    'age' : <Fragment> 
        <h2> Reduced pension warning </h2>
        <p> 
            The earliest age you can claim benefits without any reductions is called your normal pension age, or NPA. You have a different NPA for different parts of the Scheme, which means you can take your benefits at different times, or choose to take some early and have them reduced.
        </p>
        <ul className = {classes.bullets}> 
        {props.legacySchemeName === '1995 Section' ? 
        <li> For your 1995 benefits, the earliest you can take them unreduced is age 60 </li> :
        <li>  For your 2008 benefits, the earliest you can take them unreduced is age 65 </li>
        }
        <li> For your 2015 benefits, it’s either 65 or your State Pension Age – whichever is later.
            In your case, your State Pension Age is {clean_spa_age}, 
            so your 2015 Scheme Normal Pension Age is {numeric_spa <= 65 ? '65' : clean_spa_age}
             </li>
        </ul>

    </Fragment>,
    'option_a_ls' : <Fragment> 
    <h2> Tax Threshold Exceeded </h2>
    <p> The nominal lump sum you would receive under Option A is more than the tax-free limit of £268,275.
        You may be liable to tax charges on the part of the lump sum that exceeds this threshold.
         </p>
    </Fragment>,
    'option_b_ls' : <Fragment> 
    <h2> Tax Threshold Exceeded </h2>
    <p> The nominal lump sum you would receive under Option B is more than the tax-free limit of £268,275.
        You may be liable to tax charges on the part of the lump sum that exceeds this threshold.
         </p>
    </Fragment>
    }
    return(
        <div className = {classes.retirement_age_warning}
        style = {
            (
                (legacy_npa_date <= retirementDate ) &&
                 (reformed_npa_date <= retirementDate) &&
                 (!props.tax_threshold_exceeded_care) && 
                 (!props.tax_threshold_exceeded_legacy)
                ) ?
            {'margin' : 0, 'opacity' : 0, 'overflow' : 'hidden', 'padding' : 0, 'maxHeight' : 0, 'width' : 0} :
            {'maxHeight' : '1000px'}
        }
        >
            <h2> Reduced Pension and tax warnings</h2>
        <div className = {classes.warnings_container}> 
            
            <div className = {classes.first_warning + ' ' + (reformed_npa_date > retirementDate ? classes.visible : '')}> 
                <div className = {classes.image_container}> 
                    <img src = {Image}/>
                    <button
                    onClick = {() => setShowModal('age')}
                    > 2015 Scheme benefits reduced due to early payment </button>
                </div>
            </div>

            <div
            className = {classes.second_warning + (legacy_npa_date > retirementDate ? ' ' + classes.visible : '')}
            > 
            <div className = {classes.image_container}> 
                    <img src = {Image}/>
                    <button
                    onClick = {() => setShowModal('age')}
                    > {props.legacySchemeName} benefits reduced due to early payment</button>
                </div>
            </div>

            <div
            className = {classes.third_warning + (props.tax_threshold_exceeded_legacy ? ' ' + classes.visible : '')}
            > 
                <div className = {classes.image_container}> 
                    <img src = {Image}/>
                    <button
                    onClick = {() => setShowModal('option_a_ls')}
                    > Lump sum tax threshold exceeded for Option A
                    </button>
                </div>
            </div>
            <div
            className = {classes.fourth_warning + (props.tax_threshold_exceeded_care ? ' ' + classes.visible : '')}
            > 
                <div className = {classes.image_container}> 
                    <img src = {Image}/>
                    <button
                    onClick = {() => setShowModal('option_b_ls')}
                    > Lump sum tax threshold exceeded for Option B
                    </button>
                </div>
            </div>
        </div>
        {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage[showModal]} />
      )}
        </div>
    )
}

export default RetirementAgeWarning
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { submitInputData } from "../../shared/apis/mainAPI";
import { InputDataContext } from "../../shared/contexts/InputDataContext";
import { OutputDataContext } from "../../App";
import classes from "./CalculateButton.module.css";

const CalculateButton = () => {

  const navigate = useNavigate();
  const { inputsRef } = useContext(InputDataContext);
  

  const { outputDataRef } = useContext(OutputDataContext);

  const handleSubmit = async () => {
    console.log('inputsRef in calculate button', inputsRef.current)
    try {
      const data = await submitInputData(inputsRef.current);
      outputDataRef.current = data;
      inputsRef.current.retirementAge = data['retirementAge']
      inputsRef.current.retirementDate = data['retirementDate']
      navigate("/simple-view");
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      // Handle any errors that might occur during handleSubmit
    }
  };


  return (
    <button className={classes.pagebutton} onClick={handleSubmit}>
      Calculate
    </button>
  );
};

export default CalculateButton;

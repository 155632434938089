import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from './TableMainResults.module.css'
import { currencyFormatter } from "../../../utilities/Formatters";



const TableMainResults = (props) => {
    
    return(
        <div className = {classes.table_container}> 
        <table className= {classes.main_table}> 
            <thead> 
                <tr> 
                    <td> Benefit </td>
                    <td> Option A - Standard Benefits</td>
                    <td> Option B - Alternative Benefits</td>
                </tr>
            </thead>
            <tbody> 
                <tr> 
                    <td>
                        {props.legacySchemeName + ' pension'}
                    </td>
                    <td> 
                        {currencyFormatter(props.pension.choose_legacy.old)}
                    </td>
                    <td> 
                        {currencyFormatter(props.pension.choose_reformed.old)}
                    </td>
                </tr>
                <tr> 
                    <td>
                    {props.remedySchemeName + ' pension'}
                    </td>
                    <td> 
                    {currencyFormatter(props.pension.choose_legacy.new)}
                    </td>
                    <td> 
                        {currencyFormatter(props.pension.choose_reformed.new)}
                    </td>
                </tr>
                <tr> 
                    <td>
                        Lump sum
                    </td>
                    <td> 
                        {currencyFormatter(props.lump_sum.choose_legacy)}
                    </td>
                    <td> 
                        {currencyFormatter(props.lump_sum.choose_reformed)}
                    </td>
                </tr>
            </tbody>

        </table>
        </div>
    )
}

export default TableMainResults
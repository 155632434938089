import React, { Fragment, useState, useRef, useEffect } from "react";
import InfoModal from "../../general/InfoModal";
import params from "../../../shared/utilities/global_parameters";
import {textToPercent, processPercentDelete, sanitisePercentString} from '../../../utilities/PercentageHandlingFunctions'
import { submitInputData } from "../../../shared/apis/mainAPI";

import classes from "./ControlSwitches.module.css";

const SalarySlider = (props) => {




  const [showModal, setShowModal] = useState(false);
  const [mouseDown, setMouseDown] = useState(false)
  const [text_focused, set_text_focused] = useState(false)
  const [salaryIncrease, setSalaryIncrease] = useState(props.inputsRef.current.salaryIncrease)
  const initial_mount = useRef(true)
  const min_salary_increase = 0
  const max_salary_increase = 5

  function changeHandler(event) {
    
    const old_val = textToPercent(salaryIncrease);
    
    var new_val = processPercentDelete(event.target.value, old_val).replace(
      "%",
      ""
    );
  
    new_val = sanitisePercentString(new_val);
   

    const ends_with_decimal = new_val.charAt(new_val.length - 1) === '.'
    new_val = Math.min(Number(new_val/100), max_salary_increase/100)
    new_val = Math.max(new_val, min_salary_increase/100)
   
    setSalaryIncrease(String(Math.round(new_val*1000)/10) + (ends_with_decimal ? '.' : ''))
  }

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const sliderHandler = (event) => {
    setMouseDown(true);
    setSalaryIncrease(event.target.value);
  };


  useEffect(() => {
    if ((!initial_mount.current) && !mouseDown && !text_focused) {
      console.log('API triggered from salary increase switch');
  
      // Define an async function inside useEffect
      const fetchData = async () => {
        props.inputsRef.current['salaryIncrease'] = salaryIncrease;
        try {
          const data = await submitInputData(
            {...props.inputsRef.current, 'salaryIncrease': salaryIncrease}
          );
          props.setOutputData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle any errors here
        }
      };
  
      // Call the async function
      fetchData();
    } else {
      initial_mount.current = false;
    }
  }, [salaryIncrease, mouseDown, text_focused]);
  

  const infoMessage = (
    <Fragment>
      <h2>Salary Increases</h2>
      <p>
        We assume that CPI will be 2% per annum. This means a salary increase of
        2% per annum cancels out inflation and therefore salary remains constant
        in real terms. A figure higher than 2% represents year-on-year salary
        increases in real terms, whereas a figure below 2% represents
        year-on-year salary decreases in real terms.
      </p>
      <p> 
        The value set on the slider can affect the modeller's assumptions about both 
        your past and future salary. If you did not provide a salary for 1 April 2015 on the 
        'Your Details' page, setting the salary increase to a higher value will cause the 
        model to estimate a lower value for your past salary, which in turn will reduce the 
        2015 Section pension that it will estimate
        you accrued over the remedy period. 
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container  + ' ' + classes.salary_slider} style = {{'width' : '100%'}}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <header className={classes.header}>
        <h3>Salary Increases</h3>
        <button
          id="LumpSumSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help 
        </button>
      </header>
      
      <div className={classes.radio_container}>
        <input
          id="lumpSum"
          type="range"
          min={String(min_salary_increase)}
          max={String(max_salary_increase)}
          step="0.1"
          value={salaryIncrease}
          onChange={sliderHandler}
          className="slider"
          onMouseUp={() => {
            setMouseDown(false);
          }}
          onKeyUp={() => {
            setMouseDown(false);
          }}
        />
        <input type = 'text' value = {salaryIncrease + '%'} 
        onChange = {changeHandler}
        onFocus = {() => set_text_focused(true)}
        onBlur = {() => set_text_focused(false)}
        />
      </div>
    </div>
  );
};

export default SalarySlider;

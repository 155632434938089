import React from "react";
import PageButton from "../general/buttons/PageButton";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <section className={classes.mainContainer}>
      <div className={classes.linkContainer}>
        <PageButton text="Go to T&Cs" link="/T&Cs" id="HomePage_T&Cs_Button" />
      </div>
      <PageButton
        text="Start Remedy Benefits Illustrator"
        link="/ConsentPage"
        id="HomePage_YourDetails_Button"
      />
    </section>
  );
};

export default Footer;
 
import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";

import classes from "./InfoModal.module.css";

const Backdrop = (props) => {
  return <div className={classes.background} onClick={props.onClick} />;
};

const ModalOverlay = (props) => {
  const width = props.width ? props.width : '30rem'
  const top = props.top ? props.top : '20vh'
  const height = props.height ? props.height : 'fit-content'
  return (
    <div className={classes.modal}
    style = {{'--width' : width, '--top' : top, '--height' : height}}
    >
      {props.infoMessage}
      <button className={classes.btn_main} onClick={props.onClick}>
        Close this window
      </button>
    </div>
  );
};

const Modal = (props) => {
  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        props.modalHandler();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          onClick={props.modalHandler}
          infoMessage={props.infoMessage}
          width = {props.width}
          top = {props.top}
          height = {props.height}
        />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop onClick={props.modalHandler} />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default Modal;

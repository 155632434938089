import React, { Fragment } from "react";
import params from "../../global_parameters";

const FrontPageQuestionSelectorText = [
  {
    id: "FAQs-Q1",
    question: "What does this illustrator do?",
    answer: (
      <Fragment>
        <p>
          The Remedy Benefits Illustrator gives an estimate of your projected basic 
          annual pension and lump sum depending on whether you choose the 1995/2008 
          Scheme or the 2015 Scheme for the remedy period, which is between 1 April 
          2015 and 31 March 2022.
        </p>
        <p>
          Any benefits you built up before or after the remedy period aren’t affected 
          by your remedy decision, but they’ll be included in your illustration of your 
          total projected benefits. 
        </p>
        <p>
          The illustrator will show you a simple overview of your total pension 
          benefits and how they are affected by your remedy choice. You can also look 
          at a detailed view which explains your choice in more detail – in this 
          section of the tool, you can adjust things like how much lump sum you want to take, 
          your retirement date, inflation, and expected final salary to see how they 
          affect your pension benefits. 
        </p>
        <p>
          This illustrator is only as accurate as the information you put in, so it’s 
          a good idea to have your ABS to hand.
        </p>
      </Fragment>
    ),
  },
  {
    id: "FAQs-Q2",
    question: "When will I make my choice?",
    answer: (
      <Fragment>
      <p>
        If you’re still a member of the Scheme, you will be asked to choose either 
        1995/2008 Scheme or 2015 Scheme benefits for the remedy period when you 
        apply for your pension benefits. 
      </p>
      <p>
        You can use this illustrator any time before you apply to help you understand 
        your choice. Once you have applied to retire, you will be sent a letter 
        asking you to respond with your decision. 
      </p>
      <p>
        This letter will include an accurate statement showing you how much your 
        total pension benefits are worth depending on the part of the Scheme you 
        choose for the remedy period. 
      </p>
      <h3>If you are ready to apply for retirement</h3>
      <p>
        If you are still employed by the NHS, speak to your employer to jointly 
        complete the retirement application form. 
      </p>
      </Fragment>
    ),
  },
  {
    id: "FAQs-Q3",
    question: "Where will I find the information to complete this illustrator?",
    answer: (
      <Fragment>
        <p>
          Please have a copy of your latest Annual Benefit Statement to hand. 
          You can find this online in your <a href="https://mynhspension.nhsbsa.nhs.uk/home/">My NHS Pension</a> account 
          or on your Total Reward Statement through ESR. It’s also helpful to have a payslip ready, 
          so you can answer questions about your pay as accurately as possible. 
        </p>
        <p>
          We’ll ask you for information including: your date of birth, the date you joined 
          the Scheme, if you have already left the Scheme, your current whole-time equivalent 
          pensionable pay (the annual amount before tax), your pensionable pay in 2015, the 
          date you plan to retire, and your part-time work history including whole-time equivalent pay. 
        </p>
      </Fragment>
    ),
  },
  {
    id: "FAQs-Q4",
    question: "What if I want to retire earlier or later? ",
    answer: (
      <Fragment>
        <p>
        The illustrator asks for the date you would like to retire. On your detailed results page, you can change this date to see how retiring earlier or later affects the value of your basic service benefits.   
        </p>
      </Fragment>
    ),
  },
  {
    id: "FAQs-Q5",
    question: "What is my retirement age?",
    answer: (
      <Fragment>
        <p>
        The illustrator asks you to give your date of birth and the date you would like to retire. If your age on your chosen retirement date is lower than your normal pension age (NPA), the figures will automatically apply reductions for early payment because you will receive your benefits for longer. Your NPA is the earliest you can take your benefits without any reductions.   
        </p>
        <h3>Each part of the scheme has a different NPA:</h3>
        <p>
          For the 1995 Section, it’s age 60. For members with Special Class or Mental Health 
          Officer status, it’s age 55. 
        </p>
        <p>
          For the 2008 Section, it’s age 65.
        </p>
        <p>
          For the 2015 Scheme, it’s the same as your State Pension Age.
        </p>
        You don’t have to claim your 1995/2008 Scheme and your 2015 Scheme benefits all at the same time.  
        <p>
          For example, you could choose to take your 1995/2008 Scheme benefits now, and leave your 
          2015 Scheme benefits until later – this means they’ll be worth more 
          because you’ll be closer to your NPA. 
        </p>
        <p>
          You can use the tools in the detailed results page of this illustrator to show you how 
          claiming benefits at different times could affect the value of your pension.  
        </p>
      </Fragment>
    ),
  },
  {
    id: "FAQs-Q6",
    question: "What is the difference between Standard and Alternative benefits?",
    answer: (
      <Fragment>
        <p>
          When you use this tool, you’ll be shown two options to consider. When you apply to 
          retire, you’ll need to confirm which benefits you want for the remedy period. 
          You can choose either:
        </p>
        <p>
          <b> Option A: Standard benefits</b>
        </p>
        <p>
        1995/2008 Scheme benefits for your membership up to 1 April 2015,  
          <br></br>
          plus <b> 1995/2008 Scheme benefits for the remedy period, </b>
          <br></br>
          plus 2015 Scheme benefits from 1 April 2022. 
        </p>
        
        <p> 
         <b> Option B: Alternative benefits.  </b>
        </p> 
        <p>
          1995/2008 Scheme benefits for your membership up to 1 April 2015, 
          <br></br>
          plus <b> 2015 Scheme benefits for the remedy period,  </b>
          <br></br>
          plus 2015 Scheme benefits from 1 April 2022. 
        </p> 
        <p>
          Everyone’s situation is different, so don’t assume that Standard benefits will be best. 
          Many members will find that 2015 Scheme benefits for the remedy period offer them a 
          higher value pension. This tool can help you to understand what’s right for you and 
          your personal circumstances.
        </p>
      </Fragment>
    ),
  },
];

export default FrontPageQuestionSelectorText;
